import React, { useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Modal,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { CUSTOMCOLORS } from '../../styles';
import logo from '../../assets/logo.png'
import rx from '../../assets/rx.png'
import foot from '../../assets/footer.png'
import { Text } from '../Text';
import dayjs from 'dayjs';

import { useState } from 'react';
import { capitalizeWord, handleDoctorname, PrintPDF } from '../../utility/const';
import { URL } from '../../utility/urls';

export const PhramacyPreview = ({ PrescriptionData, patientData, open, onClose, doc_data, roles_data }) => {
  const doctor_name = doc_data?.actor?.display
  const { token } = useSelector(
    (state) => state.login
  );
  const { organization_data } = useSelector((state) => state?.organization)
  const organization_address = organization_data?.organization?.address[0];
  const prescribedata = PrescriptionData?.prescribe;
  const handleClose = () => {
    onClose(!open);
  };
  const [base64str, setBase64Str] = useState('');
  const [printOpen, setPrintOpen] = useState(false);

  const speciality = (roles_data ?? [])
    .map((item) =>
      (item?.role?.specialty[0]?.coding ?? []).map((val) => val?.display)
    )
    .flat();
  const PrintModal = ({ base64 }) => {
    return (
      <Modal
        open={printOpen}
        onClose={() => {
          setPrintOpen(!open);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={modalStyle}>
          <iframe
            title='pharmacy.pdf'
            src={base64}
            width='100%'
            height='100%'
          />
        </Box>
      </Modal>
    );
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <div
          style={{
            // height: "1140px",
            // width: "795px",
            padding: 24,
            position: 'relative',
          }}
        >
          <header
            id='pharma-head'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottomWidth: 2,
              borderBottomColor: CUSTOMCOLORS.primary,
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <img
                src={
                  organization_data?.org_photo?.data
                    ? organization_data?.org_photo?.data
                    : logo
                }
                alt='img'
                style={{
                  objectFit: 'contain',
                  height: '64px',
                  width: '64px',
                }}
              />
              <div>
                <p
                  style={{
                    alignSelf: 'flex-end',
                    color: CUSTOMCOLORS.primary,
                    fontSize: 14,
                    fontWeight: 'bolder',
                  }}
                >
                  {handleDoctorname(doctor_name)}
                </p>
                <p
                  style={{
                    alignSelf: 'flex-end',
                    color: CUSTOMCOLORS.primary,
                    fontSize: 12,
                  }}
                >
                  {[...new Set(speciality)].join(',')}
                </p>
                <p
                  style={{
                    alignSelf: 'flex-end',
                    color: CUSTOMCOLORS.primary,
                    fontSize: 12,
                  }}
                >
                  {doc_data?.profile?.medicalRegestrationNumber}
                </p>
              </div>
            </div>
            <div>
              <b
                style={{
                  alignSelf: 'flex-end',
                  color: CUSTOMCOLORS.primary,
                  fontSize: 14,
                }}
              >
                {organization_data?.organization?.name}
              </b>
              <br />
              <p
                style={{
                  alignSelf: 'flex-end',
                  color: CUSTOMCOLORS.primary,
                  fontSize: 12,
                }}
              >
                {`${organization_address?.text},${organization_address?.city},${organization_address?.state},${organization_address?.country},${organization_address?.postalCode}.`}
              </p>
            </div>
          </header>
          <div id='phramcy-pdf'>
            <div style={{ marginBottom: 10 }}>
              <Avatar
                variant='rounded'
                src={rx}
              />
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Text
                  weight={'400'}
                  size={12}
                >{`${patientData?.name[0]?.prefix[0]}. ${patientData?.name[0]?.text} | ${patientData?.gender} | ${patientData?.telecom[0]?.value}`}</Text>
                <Text
                  weight={'400'}
                  size={12}
                >
                  Date & Time:{' '}
                  {dayjs(new Date()).format('DD-MM-YYYY, HH:mm:ss')}
                </Text>
              </Stack>
            </div>
            {prescribedata?.length > 0 && (
              <Stack>
                <Text
                  weight={'700'}
                  color={CUSTOMCOLORS.primary}
                  size={14}
                >
                  Prescribe
                </Text>
                <Table sx={{ border: 'none' }}>
                  <TableHead>
                    <TableRow>
                      {[
                        'S.No',
                        'Medicine',
                        'Timing',
                        'Frequency',
                        'Duration',
                        'Quantity',
                        'Remarks',
                      ]?.map((value, index) => (
                        <TableCell
                          sx={{
                            color: CUSTOMCOLORS.black,
                            fontWeight: '600',
                            fontSize: 12,
                          }}
                          key={index}
                          size='small'
                        >
                          {capitalizeWord(value)}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {prescribedata?.map((item, ind) => (
                    <TableRow
                      key={ind}
                      sx={
                        ind === 5
                          ? {
                            '@media print': {
                              pageBreakAfter: 'always',
                            },
                          }
                          : {}
                      }
                    >
                      <TableCell
                        sx={{
                          fontSize: 10,
                        }}
                        size='small'
                      >
                        {ind + 1}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: 10,
                        }}
                        size='small'
                      >
                        {item?.medication?.medication[0]?.text?.toUpperCase()}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: 10,
                        }}
                        size='small'
                      >
                        {
                          item?.medication?.dosage?.additionalInformation[0]
                            ?.text
                        }
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: 10,
                        }}
                        size='small'
                      >
                        {item?.medication?.dosage?.timing?.code?.text}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: 10,
                        }}
                        size='small'
                      >
                        {item?.duration}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: 10,
                        }}
                        size='small'
                      >
                        {item?.quantity}
                      </TableCell>

                      <TableCell
                        sx={{
                          fontSize: 10,
                        }}
                        size='small'
                      >
                        {item?.medication?.note[0]?.text
                          ? item?.medication?.note[0]?.text
                          : '-'}
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Stack>
            )}
          </div>
          <footer
            id='pharma-footer'
            style={{
              width: '100%',
              position: 'fixed',
              padding: 1,
              bottom: 48,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              fontSize={10}
              textAlign={'center'}
              ml={3}
            >
              This prescription electronically signed by{' '}
              {doctor_name?.startsWith('dr') || doctor_name?.startsWith('dr.')
                ? doctor_name?.toUpperCase()
                : `Dr. ${doctor_name?.toUpperCase()}`}
              ,{[...new Set(speciality)].join(',')},
              {doc_data?.profile?.medicalRegestrationNumber},
              {new Date()?.toString()}
            </Typography>
            <Stack
              alignItems={'flex-start'}
              justifyContent={'center'}
            >
              <p
                style={{
                  fontSize: 10,
                }}
              >
                powered By
              </p>
              <Avatar
                src={foot}
                variant='rounded'
                sx={{
                  height: 28,
                  width: 28,
                  ml: 1,
                }}
              />
            </Stack>
          </footer>
        </div>
        <Box
          width={'100%'}
          position={'fixed'}
          p={1}
          bottom={0}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Button
            size='large'
            variant='contained'
            onClick={async (e) => {
              const str = await PrintPDF(
                'phramcy-pdf',
                e,
                'pharma-head',
                'pharma-footer'
              );
              setBase64Str(str);
              setPrintOpen(true);
            }}
          >
            print
          </Button>
          <PrintModal base64={base64str} />
        </Box>
      </Box>
    </Modal>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  height: '100%',
  width: 'auto',
  overflowY: 'auto',
  padding: 1,
};
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  height: `100%`,
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
  overflowY: 'auto',
};
