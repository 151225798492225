import {
  InputBase,
  Stack,
  Paper,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { snomedData } from "../utility/const";

export const SearchField = ({
  value,
  setValue,
  data,
  onClick,
  placeholder,
  size,
}) => {
  const [show, setShow] = useState(false);
  return (
    <Stack position={"relative"}>
      <Paper
        elevation={1}
        component="form"
        sx={{
          p: 1,
          display: "flex",
          alignItems: "center",
          boxShadow: "0px 0px 8px 0px #0000004A",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          value={value}
          placeholder={placeholder}
          inputProps={{ "aria-label": placeholder?.toLowerCase() }}
          onChange={(e) => {
            setValue(e.target.value);
            setShow(e.target.value ? true : false);
          }}
          size={size ? size : "medium"}
        />
        <IconButton>
          <SearchIcon />
        </IconButton>
      </Paper>
      {show && (
        <Stack
          position={"absolute"}
          width={"100%"}
          top={40}
          zIndex={50}
          sx={{
            backgroundColor: "#EDF6FF",
            maxHeight: 200,
            overflow: "scroll",
            scrollBehavior: "smooth",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {data?.map((item, index) => (
            <Typography
              onClick={() => {
                onClick(snomedData(item));
                setShow(false);
              }}
              key={index}
              variant="body2"
              p={1}
              sx={{
                cursor: "pointer",
              }}
            >
              {item?.term}
            </Typography>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
