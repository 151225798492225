import React from "react";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { isDesktopOrLaptop, istab } from "../utility/const";
export const UserCard = React.memo(({ data, onClick }) => {
  const user_data = data[0];
  return (
    <Stack
      display={"flex"}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      px={2}
      py={2}
      borderRadius={2}
      sx={{
        background: "white",
        boxShadow: "0px 0px 8px rgba(75, 165, 250, 0.5)",
      }}
    >
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={2}>
        <Avatar
          src={
            user_data?.profile_pic?.startsWith("data")
              ? user_data?.profile_pic
              : `data:image/jpeg;base64,${user_data?.profile_pic}`
          }
          sx={{
            width: 64,
            height: 64,
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />

        <Box display={"flex"} flexDirection={"column"} gap={0.2}>
          <Typography variant="subtitle1">
            {user_data?.salutation + " " + user_data?.name}
          </Typography>
          <Typography variant="body2">
            {user_data?.gender} | {user_data?.phoneNumber}
          </Typography>
          <Typography
            variant="caption"
            flexDirection={"row"}
            gap={0.5}
            display={"flex"}
          >
            Role:{" "}
            <Typography variant="body2" fontWeight={600}>
              {user_data?.user_role}
            </Typography>
          </Typography>
          <Typography
            variant="caption"
            flexDirection={"row"}
            gap={0.5}
            display={"flex"}
          >
            Clinic:{" "}
            <Typography variant="body2" fontWeight={600}>
              {user_data?.organization_reference?.display}
            </Typography>
          </Typography>
        </Box>
      </Box>

      <Button
        size={isDesktopOrLaptop || istab ? "auto" : "small"}
        variant="outlined"
        sx={{
          "&:hover": {
            bgcolor: "primary.main",
            color: "common.white",
          },
          alignSelf: isDesktopOrLaptop || istab ? "center" : "flex-start",
        }}
        onClick={onClick}
      >
        Edit
      </Button>
    </Stack>
  );
});
