import React from "react";
import PersonIcon from "@mui/icons-material/Person";

export const ImagePicker = ({ data, setValue }) => {
  return (
    <label htmlFor="profilepic">
      <div
        style={{
          width: 64,
          height: 64,
          borderRadius: 32,
          borderWidth: 1,
          borderColor: "#4ba5fa",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        {!data ? (
          <span>
            <PersonIcon sx={{ fontSize: 32, color: "#3683CC" }} />
          </span>
        ) : (
          <img
            src={data}
            alt=""
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
              borderRadius: 32,
            }}
          />
        )}

        <input
          id="profilepic"
          style={{
            display: "none",
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
          type="file"
          accept="image/*,.pdf"
          onChange={setValue}
        />
      </div>
    </label>
  );
};
