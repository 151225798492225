import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appointment_data: {},
  appointment_id: "",
  refreshVar: "",
};

const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    addAppointmentData: (state, action) => {
      state.appointment_data = action.payload;
    },
    addAppointmentId: (state, action) => {
      state.appointment_id = action.payload;
    },
    addAppointmentRefreshVar: (state, action) => {
      state.refreshVar = action.payload;
    },
  },
});

export const {
  addAppointmentData,
  addAppointmentId,
  addAppointmentRefreshVar,
} = appointmentSlice.actions;
export const AppointmentReducer = appointmentSlice.reducer;
