import React from 'react';
import { Box, Modal } from '@mui/material';
import { Text } from './Text';
import { isDesktopOrLaptop } from '../utility/const';
import { NotificationCard } from './NotificationCard';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: isDesktopOrLaptop ? '40%' : '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  height: '90%',
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
  overflowY: 'auto',
};
export const NotifyModal = ({ open, onClose, data }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        {data?.length > 0 ? (
          data?.map((item) => (
            <NotificationCard
              key={item?._id}
              item={item}
              onPress={onClose}
            />
          ))
        ) : (
          <Text>No Notifications found</Text>
        )}
      </Box>
    </Modal >
  );
};
