import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { HiPencil } from "react-icons/hi";
export const ConsultationCard = React.memo(
  ({ label, onClick, details, onEditClick }) => {
    return (
      <Stack
        border={"1px solid rgba(75, 165, 250, 0.5)"}
        py={1}
        px={2}
        borderRadius={2}
        onClick={onClick}
      >
        <Stack
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="subtitle1">{label}</Typography>
          <span className="text-2xl text-blue-400" onClick={onClick}>
            <HiPencil />
          </span>
        </Stack>
        <Box>
          {details && (
            <Typography
              variant="caption"
              onClick={(e) => {
                e.stopPropagation();
                onEditClick();
              }}
            >
              {details}
            </Typography>
          )}
        </Box>
      </Stack>
    );
  }
);
