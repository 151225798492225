import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  complaints: [],
};

const complaintSlice = createSlice({
  name: "Cheif Complaints",
  initialState,
  reducers: {
    addChiefComplaints: (state, action) => {
      state.complaints = action.payload;
    },
  },
});

export const { addChiefComplaints } = complaintSlice.actions;
export const complaintReducer = complaintSlice.reducer;
