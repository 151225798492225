import { showToast } from "./toast";
import { URL } from "./urls";

export const sendNotification = async (
  token,
  fcmTokens,
  body,
  data_notification,
  screen,
  sending_to,
  sender_name
) => {
  const NotificationDetails = {
    data: {
      screen: screen,
    },
    notification: {
      body: body,
      title: "Alert from Doctor",
    },
    registration_ids: fcmTokens,
  };
  const savingNotification = {
    screen,
    notification_data: body,
    user_ref: data_notification?.user_ref,
    org_ref: data_notification?.org_ref,
    doctor_ref: data_notification?.doctor_ref,
    patient_ref: data_notification?.patient_ref,
    appointment_ref: data_notification?.appointment_ref,
    sending_to,
    sender_name,
  };
  try {
    const response = await fetch(URL.sendNotification, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(NotificationDetails),
    });
    if (response.ok) {
      const jsonData = await response.json();
      showToast("success", jsonData?.message);
      if (data_notification) {
        try {
          await fetch(URL.postNotifyData, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(savingNotification),
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        showToast("error", "Try After sometime");
      }
    }
  } catch (error) {
    showToast("error", "Try After sometime");
  }
};

export const GetFcmTokenDoc = async (doc_id) => {
  try {
    const response = await fetch(URL.getFcmTokens(doc_id), {
      method: "GET",
    });
    if (response.ok) {
      const jsonData = await response.json();
      return jsonData?.data;
    }
  } catch (error) {
    return error;
  }
};
