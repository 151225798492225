import { createSlice } from '@reduxjs/toolkit';

const initialState = {
 patient_data:{},
 patient_id:""
};

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    addPatientData: (state, action) => {
      state.patient_data=action.payload
    },
    addPatientId:(state,action)=>{
        state.patient_id=action.payload
    }
  },
});

export const { addPatientData, addPatientId } = patientSlice.actions;
export const PatientReducer = patientSlice.reducer;