import { useDispatch } from "react-redux";
import {
  capitalizeWord,
  isDesktopOrLaptop,
  ismobile,
  istab,
} from "../utility/const";

import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { Avatar, Button, Stack, Typography } from "@mui/material";
import { PatientCreate } from "./PatientCreate";
import { addPatientData } from "../redux/features/patientData";
import {
  addAppointmentData,
  addAppointmentId,
} from "../redux/features/appointmentData";
import { CUSTOMCOLORS } from "../styles";
import { WalkInModal } from "./walkinModal";

export const PatientCard = React.memo(({ data, doc }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const birthYear = data?.patient?.birthDate?.split("-")[0];
  const presentYear = new Date()?.getFullYear();
  const [showWalkin, setshowwalkin] = useState(false);
  const handlewalkinModal = () => [setshowwalkin(false)];
  return (
    <Stack
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      px={2}
      py={1}
      border={"1px solid rgba(75, 165, 250, 0.5)"}
      borderRadius={2}
    >
      <Stack display={"flex"} flexDirection={"row"} gap={1}>
        <Avatar
          src={
            data?.patient?.photo?.data?.startsWith("data")
              ? data?.patient?.photo?.data
              : `data:image/jpeg;base64,${data?.patient?.photo?.data}`
          }
          sx={{
            width: isDesktopOrLaptop || istab ? 52 : 48,
            height: isDesktopOrLaptop || istab ? 52 : 48,
            borderRadius: 32,
            objectFit: "contain",
            border: 1,
            borderColor: CUSTOMCOLORS.border,
          }}
        />
        <Stack>
          <Typography
            variant="body1"
            fontSize={isDesktopOrLaptop || istab ? "auto" : 14}
          >
            {`${data?.patient?.name?.[0]?.prefix?.join(",")} ${capitalizeWord(
              data?.patient?.name?.[0]?.text
            )}`}
          </Typography>
          <Typography
            variant="body2"
            fontSize={isDesktopOrLaptop || istab ? "auto" : 14}
          >
            {parseInt(presentYear) - parseInt(birthYear)} |{" "}
            {data?.patient?.gender ? capitalizeWord(data?.patient?.gender) : ""}{" "}
            | {data?.patient?.telecom?.[0]?.value}
          </Typography>
          {data?.complaint && (
            <Typography
              fontSize={isDesktopOrLaptop || istab ? "auto" : 14}
              variant="body2"
            >
              Reason for visit: {data?.complaint}
            </Typography>
          )}
          {data?.patient?.identifier[0]?.value && (
            <Typography variant="body2">
              Reference ID: {data?.patient?.identifier[0]?.value}
            </Typography>
          )}
        </Stack>
      </Stack>
      {data?.patient?.complaint ? (
        ""
      ) : (
        <Stack
          display={"flex"}
          flexDirection={
            isDesktopOrLaptop || istab || ismobile ? "row" : "column"
          }
          gap={1}
        >
          {doc && (
            <Button
              size={isDesktopOrLaptop || istab ? "auto" : "small"}
              onClick={() => {
                setOpen(!open);
              }}
              variant="outlined"
            >
              Edit
            </Button>
          )}
          <Button variant="outlined" onClick={() => setshowwalkin(true)}>
            Walk In
          </Button>
          <Button
            size={isDesktopOrLaptop || istab ? "auto" : "small"}
            sx={{
              fontSize: isDesktopOrLaptop || istab ? "auto" : 12,
            }}
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              dispatch(addPatientData(data));
              dispatch(addAppointmentData({}));
              dispatch(addAppointmentId(""));
              navigate("/slotbook");
            }}
          >
            {isDesktopOrLaptop || istab ? "Book Appointment" : "Book"}
          </Button>
        </Stack>
      )}
      {open && <PatientCreate open={open} onClose={setOpen} data={data} />}
      {showWalkin && (
        <WalkInModal open={showWalkin} onClose={handlewalkinModal} pat_data={data} />
      )}
    </Stack>
  );
});
