import React from "react";
import { Autocomplete, TextField } from "@mui/material";
export const AutoCompleteComponent = ({ label, value, setValue, options }) => {
  return (
    <Autocomplete
      value={value}
      onChange={(newValue) => setValue(newValue)}
      disablePortal
      id="combo-box-demo"
      options={options}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          sx={{
            "& .MuiAutocomplete-clearIndicator": {
              display: "none",
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: 14,
              fontWeight: 500,
              fontFamily: "Open sans",
            },
          }}
        />
      )}
    />
  );
};
