export const smallHead = {
  fontSize: 14,
  fontWeight: 600,
  color: "common.black",
};
export const smallDesc = {
  fontSize: 12,
  fontWeight: 400,
  color: "common.black",
};
export const smallDescBold = {
  fontSize: 12,
  fontWeight: 600,
};
