import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VitalValuSets, vitalSchema } from "../utility/valueSets/vitals";
import { AddCircle} from "@mui/icons-material";
import { updataeComplaints_Vitals } from "../redux/features/dataUpdates";
import { showToast } from "../utility/toast";
import { URL, systems } from "../utility/urls";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { TextFieldWithAdornment } from "./base/TextFieldWithAdornment";

import { isDesktopOrLaptop, istab } from "../utility/const";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

export const Vitals = ({ open, onClose, app_data }) => {
  const { token } = useSelector((state) => state.login);
  let { vitals_update } = useSelector((state) => state?.updates);

  const [Height, setHeight] = useState("");
  const [Weight, setWeight] = useState("");
  const [bmi, setbmi] = useState("");
  const [pulse, setPulse] = useState("");
  const [spo2, setSpo2] = useState("");
  const [temp, setTemp] = useState("");
  const [rrate, setRrate] = useState("");
  const [systolic, setSystolic] = useState("");
  const [diastolic, setDiastolic] = useState("");
  const [othersVitals, setOtherVitals] = useState("");
  const [othersvalue, setOthersValue] = useState("");
  const [snomeddata, setsnomeddata] = useState([]);
  const [show, setShow] = useState(false);
  const [othersCode, setOthersCode] = useState({});
  const dispatch = useDispatch();
  const timestamp = new Date().toISOString();

  const AddBasicVitals = async () => {
    let body_data = [];
    if (pulse) {
      body_data.push(
        vitalSchema(
          app_data,
          VitalValuSets.pulse,
          pulse,
          "beats/min",
          "/min",
          "ObservationVitalSigns",
          timestamp
        )
      );
    }
    if (spo2) {
      body_data.push(
        vitalSchema(
          app_data,
          VitalValuSets.spo2,
          spo2,
          "%",
          "%",
          "ObservationVitalSigns",
          timestamp
        )
      );
    }
    if (temp) {
      body_data.push(
        vitalSchema(
          app_data,
          VitalValuSets.temp,
          temp,
          "degF",
          "[degF]",
          "ObservationVitalSigns",
          timestamp
        )
      );
    }
    if (rrate) {
      body_data.push(
        vitalSchema(
          app_data,
          VitalValuSets.resp,
          rrate,
          "beats/min",
          "/min",
          "ObservationVitalSigns",
          timestamp
        )
      );
    }
    const response = await fetch(URL.createVitals, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body_data),
    });
    const jsonData = await response.json();
    if (jsonData?.status === "success") {
      dispatch(
        updataeComplaints_Vitals({
          vitals_update: (vitals_update += "s"),
          complaint_update: "",
          reports_update: "",
        })
      );
      setPulse("");
      setSpo2("");
      setRrate("");
      setTemp("");
    } else {
      showToast("error", "please try again");
    }
  };

  const AddBPVitals = async () => {
    const apis_data = [
      vitalSchema(
        app_data,
        VitalValuSets.diastolic,
        diastolic,
        "mmhg",
        "mm[Hg]",
        "ObservationVitalSigns",
        timestamp
      ),
      vitalSchema(
        app_data,
        VitalValuSets.systolic,
        systolic,
        "mmhg",
        "mm[Hg]",
        "ObservationVitalSigns",
        timestamp
      ),
    ];
    const response = await fetch(URL.createVitals, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apis_data),
    });
    const jsonData = await response.json();
    if (jsonData?.status === "success") {
      dispatch(
        updataeComplaints_Vitals({
          vitals_update: (vitals_update += "s"),
          complaint_update: "",
          reports_update: "",
        })
      );
      setDiastolic("");
      setSystolic("");
    } else {
      showToast("error", "please try again");
    }
  };
  const handleBodyApis = () => {
    const bodymeasurements = [
      vitalSchema(
        app_data,
        VitalValuSets.height,
        Height,
        "cm",
        "[cm_us]",
        "ObservationBodyMeasurement",
        ""
      ),
      vitalSchema(
        app_data,
        VitalValuSets.weight,
        Weight,
        "kg",
        "[kg_us]",
        "ObservationBodyMeasurement",
        ""
      ),
      vitalSchema(
        app_data,
        VitalValuSets.Bmi,
        bmi,
        "kg/m2",
        "kg/m2",
        "ObservationBodyMeasurement",
        ""
      ),
    ];
    return bodymeasurements;
  };
  const handleOthersData = () => {
    const othersVitals = [
      vitalSchema(
        app_data,
        othersCode,
        parseFloat(othersvalue),
        "",
        "",
        "ObservationVitalSigns",
        ""
      ),
    ];
    return othersVitals;
  };

  const postVitalsData = async (type) => {
    const response = await fetch(URL.createVitals, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        type === "body" ? handleBodyApis() : handleOthersData()
      ),
    });
    const jsonData = await response.json();
    if (jsonData?.status === "success") {
      dispatch(
        updataeComplaints_Vitals({
          vitals_update: (vitals_update += "s"),
          complaint_update: "",
          reports_update: "",
        })
      );
    } else {
      showToast("error", "please try again");
    }
  };
  useEffect(() => {
    const handleBMI = () => {
      const heigh = (Height / 100) ** 2;
      const weight = parseInt(Weight);
      const BMI = (weight / heigh).toString().slice(0, 5);
      if (isNaN(parseInt(BMI))) {
        setbmi(0);
      } else {
        setbmi(parseFloat(BMI));
      }
    };
    handleBMI();
  }, [Weight, Height]);
  const fetchSnomed = async () => {
    const response = await fetch(
      URL.snomed(othersVitals, "observable entity"),
      {
        method: "GET",
      }
    );
    const jsonData = await response.json();
    const data = jsonData?.map((item) => ({
      system: systems.snomed,
      code: item?.conceptId,
      display: item?.term,
    }));
    setsnomeddata(data);
  };
  useEffect(() => {
    fetchSnomed();
    if (othersVitals?.length === 0) {
      setShow(false);
    }
  }, [othersVitals]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      styles={{
        modal: {
          flex: 1,
          width: isDesktopOrLaptop ? "80%" : "90%",
          height: istab ? "70%" : "90%",
          borderRadius: 16,
        },
      }}
    >
      {" "}
      <Box
        display={"flex"}
        position={"relative"}
        flexDirection={"column"}
        gap={1}
        p={1}
      >
        <Typography variant="h5" component="h2" fontWeight={700}>
          Vitals
        </Typography>
        <Stack gap={2}>
          <Typography
            display="flex"
            alignSelf="left"
            variant="h6"
            component="h2"
          >
            Basics
          </Typography>

          <Stack gap={2}>
            <Box
              display={"flex"}
              gap={1}
              flexWrap={"wrap"}
              alignItems={"center"}
            >
              <TextFieldWithAdornment
                label="Weight"
                adorntext="Kg"
                value={Weight}
                onChange={(e) => setWeight(e.target.value)}
              />

              <TextFieldWithAdornment
                label="Height"
                adorntext="cm"
                value={Height}
                onChange={(e) => setHeight(e.target.value)}
              />
              <Typography>BMI: {bmi}</Typography>
              <IconButton onClick={async () => await postVitalsData("body")}>
                <AddCircle fontSize="large" />
              </IconButton>
            </Box>

            <Box display="flex" gap={1} flexWrap={"wrap"}>
              <TextFieldWithAdornment
                label="Pulse"
                value={pulse}
                adorntext="Pulse"
                onChange={(e) => setPulse(e.target.value)}
              />

              <TextFieldWithAdornment
                label="SPO2"
                value={spo2}
                adorntext="%"
                onChange={(e) => setSpo2(e.target.value)}
              />
              <TextFieldWithAdornment
                label="Temperature"
                value={temp}
                adorntext="°F"
                onChange={(e) => setTemp(e.target.value)}
              />

              <TextFieldWithAdornment
                label="Respiration Rate"
                adorntext="brpm"
                value={rrate}
                onChange={(e) => setRrate(e.target.value)}
              />

              <IconButton onClick={AddBasicVitals}>
                <AddCircle fontSize="large" />
              </IconButton>
            </Box>
          </Stack>
        </Stack>

        <Stack gap={1}>
          <Typography
            display="flex"
            alignSelf="left"
            variant="h6"
            component="h2"
          >
            Blood Pressure
          </Typography>
          <Box display={"flex"} gap={1}>
            <TextFieldWithAdornment
              label="Systolic"
              adorntext="mmHg"
              value={systolic}
              onChange={(e) => setSystolic(e.target.value)}
            />

            <TextFieldWithAdornment
              label="Diastolic"
              adorntext="mmHg"
              value={diastolic}
              onChange={(e) => setDiastolic(e.target.value)}
            />
            <IconButton onClick={AddBPVitals}>
              <AddCircle fontSize="large" />
            </IconButton>
          </Box>
        </Stack>
        <Stack gap={1}>
          <Typography
            display="flex"
            alignSelf="left"
            variant="h6"
            component="h2"
          >
            Others
          </Typography>
          <Box display={"flex"} gap={1}>
            <Stack position={"relative"}>
              <TextFieldWithAdornment
                label="Search Name"
                value={othersVitals}
                onChange={(e) => {
                  setOtherVitals(e.target.value);
                  setShow(true);
                }}
                adorntext=""
              />
              {show && (
                <Stack
                  position={"absolute"}
                  height={200}
                  top={56}
                  sx={{
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                    background: "#E5F3FE",
                  }}
                >
                  {snomeddata?.map((item, ind) => (
                    <Typography
                      variant="body2"
                      key={ind}
                      sx={{
                        padding: 1,
                        fontSize: 12,
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setOtherVitals(item?.display);
                        setShow(false);
                        setOthersCode({
                          code: {
                            coding: [
                              {
                                system: systems.snomed,
                                code: item?.code,
                                display: item?.display,
                              },
                            ],
                            text: item?.display,
                          },
                        });
                      }}
                    >
                      {item?.display}
                    </Typography>
                  ))}
                </Stack>
              )}
            </Stack>

            <TextFieldWithAdornment
              label="Value"
              adorntext=""
              value={othersvalue}
              onChange={(e) => setOthersValue(e.target.value)}
            />
            <IconButton onClick={async () => await postVitalsData("others")}>
              <AddCircle fontSize="large" />
            </IconButton>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};
