import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { LiaFilePrescriptionSolid } from "react-icons/lia";
import { MdOutlineMedicalServices } from "react-icons/md";
import {
  capitalizeWord,
  isDesktopOrLaptop,
  istab,
} from "../utility/const";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import {
  addAppointmentData,
  addAppointmentId,
  addAppointmentRefreshVar,
} from "../redux/features/appointmentData";
import { addPatientData } from "../redux/features/patientData";
import {
  fetchSugs,
  postSuggestions,
  updateSuggestions,
} from "../utility/suggestion";
import { URL } from "../utility/urls";
import { showToast } from "../utility/toast";
import { Text } from "./Text";
import { SearchField } from "./SearchFiled";
import { PrescriptionPreview } from "./Modals/Prescription";
import { PhramacyPreview } from "./Modals/Pharmacy";
import { CUSTOMCOLORS } from "../styles";
import { smallDesc, smallDescBold, smallHead } from "../utility/styles";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";

export const AppointmentCard = React.memo(({ appointment_data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const app_data = appointment_data?.appointment_data;
  console.log(app_data);

  const pat_data = appointment_data?.patient_data;
  const { token } = useSelector((state) => state.login);
  const age =
    parseInt(new Date().getFullYear()) -
    parseInt(pat_data?.patient?.birthDate?.split("-")[0]);

  const reasons = app_data?.appointment?.reasonCode
    ?.map((item) => item?.text)
    ?.join(",");
  const [prescription, setPrescription] = useState({});

  const [presentIlness, setPresentIllness] = useState([]);
  const [chief, setChief] = useState([]);
  const [roles_data, setRole_Data] = useState([]);
  const fetchPrescription = async () => {
    const response = await fetch(URL?.getPrescriptionData(app_data?._id), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
    });
    const jsondata = await response.json();
    setPrescription(jsondata?.data);
    const newdata = jsondata?.data?.complaint?.filter(
      (item) => item?.type === "Present Illness"
    );
    setPresentIllness(newdata);
    const complaintdata = jsondata?.data?.complaint?.filter(
      (item) => item?.type === "ChiefComplaint"
    );
    setChief(complaintdata);
    const role_response = await fetch(
      URL.getRole_IE_Slots(
        app_data?.appointment?.participant[0]?.actor?.reference
      ),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const role_jsonData = await role_response.json();

    role_jsonData?.data ? setRole_Data(role_jsonData?.data) : setRole_Data([]);
  };
  let load = false;
  useEffect(() => {
    if (load) {
      return;
    }
    fetchPrescription();
    load = true;
  }, []);
  const physicalFilter = prescription?.physical?.physicalExamination?.filter(
    (item) => item?.status?.text !== "" && item?.status?.text !== undefined
  );

  const [pr_open, setPr_Open] = useState(false);
  const [ph_open, setPh_Open] = useState(false);
  const ischeck = isDesktopOrLaptop || istab;
  return (
    <Stack position={"relative"}>
      <Typography
        position={"absolute"}
        top={0}
        p={1}
        variant="caption"
        sx={{
          background: CUSTOMCOLORS.primary,
          borderBottomRightRadius: 16,
          borderTopRightRadius: 16,
          borderTopLeftRadius: 4,
        }}
        zIndex={1}
        color={"common.white"}
      >
        {app_data?.appointment?.priority}
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          border: "1px solid rgba(75, 165, 250, 0.5)",
          p: ischeck ? 2 : 1,
          borderRadius: 2,
          gap: 1,
          alignItems: "flex-end",
        }}
      >
        <Box
          display={"flex"}
          width={1}
          gap={1}
        >
          <Box
            display={"flex"}
            width={1}
            gap={ischeck ? 0.5 : 1}
          >
            <Box
              display={"flex"}
              width={ischeck ? "8%" : "20%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Avatar
                sx={{
                  width: ischeck ? 64 : 56,
                  height: ischeck ? 64 : 56,
                  border: 2,
                  borderColor: CUSTOMCOLORS.fadeblue,
                }}
                src={
                  pat_data?.patient?.photo?.data?.startsWith("data")
                    ? pat_data?.patient?.photo?.data
                    : `data:image/jpeg;base64,${pat_data?.patient?.photo?.data}`
                }
              ></Avatar>
            </Box>
            <Box width={ischeck ? "55%" : "48%"}>
              <Typography
                variant="subtitle1"
                sx={{
                  "@media (max-width: 450px)": smallHead,
                }}
              >
                {`${pat_data?.patient?.name[0]?.prefix?.join(
                  ","
                )}. ${capitalizeWord(pat_data?.patient?.name[0]?.text)}`}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  "@media (max-width: 450px)": smallDesc,
                }}
              >
                {" "}
                {age} | {capitalizeWord(pat_data?.patient.gender)}
              </Typography>
              <Typography
                variant="subtitle2"
                display={"flex"}
                gap={0.5}
                sx={{
                  "@media (max-width: 450px)": {
                    fontSize: 12,
                    fontWeight: 500,
                  },
                }}
              >
                {ischeck ? "Reason for visit:" : "Reason:"} {reasons}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={ischeck ? "row" : "column"}
              alignItems={ischeck ? "center" : "flex-end"}
              justifyContent={ischeck ? "center" : "flex-end"}
              gap={1}
              width={ischeck ? "35%" : "30%"}
            >
              {app_data?.appointment?.status?.toLowerCase() === "cancelled" ? (
                ""
              ) : (
                <>
                  {ischeck && (
                    <Button
                      variant="outlined"
                      size={ischeck ? "medium" : "small"}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(addAppointmentId(app_data?._id));
                        dispatch(addAppointmentData(app_data));
                        dispatch(addPatientData(pat_data));
                        navigate("/slotbook");
                      }}
                      sx={{
                        "@media (max-width: 375px)": {
                          fontSize: 10,
                        },
                        "@media (min-width: 375px) and (max-width: 450px)": {
                          fontSize: 12,
                        },
                      }}
                    >
                      Reschedule
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    size={ischeck ? "medium" : "small"}
                    onClick={() => {
                      dispatch(addAppointmentId(app_data?._id));
                      dispatch(addAppointmentData(app_data));
                      dispatch(addPatientData(pat_data));
                      navigate("/consultation");
                    }}
                    sx={{
                      "@media (max-width: 375px)": {
                        fontSize: 10,
                      },
                      "@media (min-width: 375px) and (max-width: 450px)": {
                        fontSize: 12,
                        display: "flex",

                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      },
                    }}
                  >
                    Start Visit
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Stack
          mt={1}
          borderRadius={ischeck ? 100 : 1}
          alignItems={"center"}
          bgcolor={"primary.light"}
          p={1}
        >
          {app_data?.appointment?.status?.toLowerCase() === "cancelled" ? (
            <Stack
              direction={"row"}
              gap={4}
              display={"flex"}
              justifyContent={"space-around"}
            >
              <Stack direction={"row"} spacing={0.2} alignItems={"center"}>
                <Typography
                  variant="body2"
                  sx={{
                    "@media (max-width: 450px)": smallDesc,
                  }}
                >
                  Status:
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    "@media (max-width: 450px)": smallDescBold,
                  }}
                >
                  {" "}
                  {capitalizeWord(app_data?.appointment?.status)}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={0.2} alignItems={"center"}>
                <Typography
                  variant="body2"
                  sx={{
                    "@media (max-width: 450px)": smallDesc,
                  }}
                >
                  {ischeck ? "Reason For Cancellation:" : "Reason:"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    "@media (max-width: 450px)": smallDescBold,
                  }}
                >
                  {" "}
                  { }
                  {app_data?.appointment?.cancelationReason?.text
                    ? capitalizeWord(
                      app_data?.appointment?.cancelationReason?.text
                    )
                    : ""}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Stack
              direction={"row"}
              gap={4}
              display={"flex"}
              flexWrap={"wrap"}
              sx={{
                "@media (max-width: 375px)": {
                  gap: 0.5,
                  alignItems: "center",
                  justifyContent: "center",
                },
                "@media (min-width: 375px) and (max-width: 450px)": {
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "center",
                },
                "@media (min-width: 450px) and (max-width: 680px)": {
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
            >
              <Stack direction={"row"} spacing={0.2} alignItems={"center"}>
                <Typography
                  variant="body2"
                  sx={{
                    "@media (max-width: 450px)": smallDesc,
                  }}
                >
                  Type:
                </Typography>
                <Typography
                  variant={ischeck ? "subtitle1" : "subtitle2"}
                  sx={{
                    "@media (max-width: 450px)": smallDescBold,
                  }}
                >
                  {capitalizeWord(
                    app_data?.appointment?.appointmentType?.coding[0]?.code ===
                      "WALKIN"
                      ? "New"
                      : app_data?.appointment?.appointmentType?.coding[0]?.code
                  )}
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} spacing={0.2}>
                <Typography
                  variant="body2"
                  sx={{
                    "@media (max-width: 450px)": smallDesc,
                  }}
                >
                  Slot:
                </Typography>
                <Typography
                  variant={ischeck ? "subtitle1" : "subtitle2"}
                  sx={{
                    "@media (max-width: 450px)": smallDescBold,
                  }}
                >
                  {" "}
                  {app_data?.slot}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={0.2} alignItems={"center"}>
                <Typography
                  variant="body2"
                  sx={{
                    "@media (max-width: 450px)": smallDesc,
                  }}
                >
                  Status:
                </Typography>
                <Typography
                  variant={ischeck ? "subtitle1" : "subtitle2"}
                  color={
                    app_data?.appointment?.status === "fulfilled"
                      ? "success.main"
                      : "error"
                  }
                  sx={{
                    "@media (max-width: 450px)": smallDescBold,
                  }}
                >
                  {" "}
                  {capitalizeWord(
                    app_data?.appointment?.status === "fulfilled"
                      ? "completed"
                      : app_data?.appointment?.status
                  )}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={0.2} alignItems={"center"}>
                <Typography
                  variant="body2"
                  sx={{
                    "@media (max-width: 450px)": smallDesc,
                  }}
                >
                  Bill:
                </Typography>
                <Typography
                  variant={ischeck ? "subtitle1" : "subtitle2"}
                  sx={{
                    "@media (max-width: 450px)": smallDescBold,
                  }}
                >
                  {" "}
                  {app_data?.fees}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
        {app_data?.appointment?.status?.toLowerCase() === "fulfilled" ? (
          <Stack
            position={"absolute"}
            right={ischeck ? 8 : 2}
            top={0}
            direction={"row"}
            gap={0}
            fontSize={isDesktopOrLaptop ? 32 : 24}
            className="cursor-pointer"
          >
            <Box
              paddingY={1}
              paddingX={0.5}
              sx={{
                color: "primary.main",
              }}
            >
              <LiaFilePrescriptionSolid onClick={() => setPr_Open(true)} />
            </Box>
            <Box
              paddingY={1}
              paddingX={0.5}
              sx={{
                color: "primary.main",
              }}
            >
              <MdOutlineMedicalServices onClick={() => setPh_Open(true)} />
            </Box>

          </Stack>
        ) : (
          <>
            {app_data?.appointment?.status?.toLowerCase() !== "cancelled" && (
              <Stack
                position={"absolute"}
                right={2}
                top={0}
                p={ischeck ? 1 : 0.8}
                direction={"row"}
                spacing={1}
                className="cursor-pointer"
                display={"flex"}
                flexDirection={"row"}
              >
                {!ischeck && (
                  <EditCalendarIcon
                    fontSize={"small"}
                    sx={{
                      color: "primary.main",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(addAppointmentId(app_data?._id));
                      dispatch(addAppointmentData(app_data));
                      dispatch(addPatientData(pat_data));
                      navigate("/slotbook");
                    }}
                  />
                )}
                <CloseIcon
                  fontSize={ischeck ? "medium" : "small"}
                  onClick={() => setOpen(!open)}
                  sx={{ color: "error.main" }}
                />
              </Stack>
            )}
          </>
        )}
        <CancelAppointmentModal open={open} setOpen={setOpen} data={app_data} />
        <PrescriptionPreview
          doc_data={app_data?.appointment?.participant[0]}
          open={pr_open}
          onClose={setPr_Open}
          patientData={pat_data?.patient}
          roles_data={roles_data}
          PrescriptionData={{
            ReasonForVisit: chief?.length > 0 ? chief : {},
            Vitals:
              prescription?.vitals?.length > 0 ? prescription?.vitals : {},
            allergy:
              prescription?.allergy?.length > 0 ? prescription?.allergy : {},
            symptoms:
              prescription?.symptom?.length > 0 ? prescription?.symptom : {},
            presentIllness: presentIlness?.length > 0 ? presentIlness : {},
            diagnosis:
              prescription?.diagnosis?.length > 0
                ? prescription?.diagnosis
                : {},
            investigation:
              prescription?.procedure?.length > 0
                ? prescription?.procedure
                : {},
            followup:
              prescription?.followup?.length > 0 ? prescription?.followup : {},
            advice:
              prescription?.advice?.length > 0 ? prescription?.advice : {},
            prescribe:
              prescription?.prescription?.length > 0
                ? prescription?.prescription
                : {},
            fees:
              prescription?.fees?.fees?.length > 0
                ? prescription?.fees?.fees
                : [],
            reportsFindings:
              prescription?.report_findings?.text_data?.length > 0
                ? prescription?.report_findings?.text_data
                : [],
            physicalExamination:
              physicalFilter?.length > 0 ? physicalFilter : [],
          }}
        />
        <PhramacyPreview
          roles_data={roles_data}
          PrescriptionData={{
            prescribe:
              prescription?.prescription?.length > 0
                ? prescription?.prescription
                : {},
          }}
          onClose={setPh_Open}
          open={ph_open}
          doc_data={app_data?.appointment?.participant[0]}
          patientData={pat_data?.patient}
        />
      </Box>
    </Stack>
  );
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isDesktopOrLaptop ? "40%" : "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "42%",
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  gap: 1,
  overflowY: "auto",
};

const CancelAppointmentModal = ({ open, setOpen, data }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [cancel_code, setCancel_Code] = useState({});
  const [snomedStr, setSnomedStr] = useState("");
  const [snomedData, setSnomedData] = useState([]);
  const { phoneNumber, token, profile_id } = useSelector(
    (state) => state.login
  );
  let { refreshVar } = useSelector((state) => state?.appointment);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (status === "cancelled") {
      if (!cancel_code?.text) {
        showToast("error", "Please select cancel reason");
        return;
      }
    }
    try {
      const response = await fetch(URL.updateStatusAppointment(data?._id), {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status: status,
          cancelationReason: cancel_code,
          confirm: status === "cancelled" ? "" : data?.slot,
          fees: "",
        }),
      });
      const jsonData = await response.json();
      if (jsonData?.status === "success") {
        dispatch(addAppointmentRefreshVar((refreshVar += "s")));
        showToast("success", "Sucessfully rescheduled appointment");
        setOpen(!open);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchSnomed = async () => {
    const response = await fetch(URL.snomed(snomedStr, "finding"), {
      method: "GET",
    });
    const jsonData = await response.json();
    console.log(data);

    setSnomedData(jsonData);
  };
  useEffect(() => {
    if (snomedStr?.length > 2) {
      fetchSnomed();
    }
  }, [snomedStr]);
  const handleCode = async (item) => {
    setSnomedStr(item?.display);
    setCancel_Code({ coding: [item], text: item?.display });
    if (suggs?.length > 0) {
      await updatetSuggestionsData(item);
    } else {
      await postSuggestionsData(item);
    }
  };
  const [suggs, setSugs] = useState([]);
  const [sugUpdate, setSugUpdate] = useState("");

  useEffect(() => {
    const fetchSug = async () => {
      const data = await fetchSugs(profile_id, "cancelsReasons");
      setSugs(data);
    };
    fetchSug();
    return () => { };
  }, [sugUpdate]);

  const postSuggestionsData = async (item) => {
    const postdata = await postSuggestions(item, profile_id, "cancelsReasons");
    if (postdata === "success") {
      setSugUpdate((data) => (data += "u"));
    }
  };
  const updatetSuggestionsData = async (item) => {
    const postdata = await updateSuggestions(
      item,
      profile_id,
      "cancelsReasons"
    );
    if (postdata === "success") {
      setSugUpdate((data) => (data += "u"));
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(!open)}>
      <Box sx={style}>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <Stack spacing={2}>
            <Stack>
              <Text>Select Status</Text>
              <Stack direction={"row"} spacing={1}>
                {["cancelled", "noshow"]?.map((item) => (
                  <Chip
                    key={item}
                    label={item?.toUpperCase()}
                    variant={status === item ? "filled" : "outlined"}
                    onClick={() => setStatus(item)}
                  />
                ))}
              </Stack>
            </Stack>
            {status === "cancelled" && (
              <Stack>
                <SearchField
                  value={snomedStr}
                  setValue={setSnomedStr}
                  data={snomedData}
                  label="Search complaints"
                  placeholder="Search complaints"
                  onClick={handleCode}
                />
                <Stack
                  direction={"row"}
                  spacing={0.5}
                  mt={0.5}
                  flexWrap={"wrap"}
                >
                  {suggs && (
                    <>
                      {suggs?.length > 0 &&
                        suggs?.map((item, index) => (
                          <Chip
                            key={index}
                            label={item?.display}
                            onClick={() => {
                              setCancel_Code({
                                coding: [item],
                                text: item?.display,
                              });
                              setSnomedStr(item?.display);
                            }}
                            onDelete={async () => {
                              const updatingSugs = suggs?.filter(
                                (_, ind) => ind !== index
                              );
                              updatingSugs?.length > 0
                                ? await postSuggestionsData(updatingSugs)
                                : showToast(
                                  "error",
                                  "Atleast one suggestion You want to delete another one"
                                );
                            }}
                          />
                        ))}
                    </>
                  )}
                </Stack>
              </Stack>
            )}
          </Stack>
          <Button
            sx={{ alignSelf: "center", mt: 1 }}
            variant="contained"
            type="submit"
          >
            Submit
          </Button>
        </form>
      </Box>
    </Modal>
  );
};
