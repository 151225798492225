import { URL } from "./urls";

export const fetchSugs = async (profile_id, resource) => {
  const response = await fetch(URL.getRecents(profile_id, resource), {
    method: "GET",
  });
  const jsondata = await response.json();
  return jsondata?.data?.data;
};

export const postSuggestions = async (item, profile_id, resource) => {
  const response = await fetch(URL.createRecents, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      type: resource,
      data: [item],
      user_id: profile_id,
    }),
  });
  const jsondata = await response.json();
  if (jsondata?.status === "success") {
    return jsondata?.status;
  }
  return jsondata?.status;
};
export const updateSuggestions = async (item, profile_id, resource) => {
  const response = await fetch(URL.updatingRecents(profile_id, resource), {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(item),
  });
  const jsondata = await response.json();
  if (jsondata?.status === "success") {
    return jsondata?.status;
  }
  return jsondata?.status;
};
