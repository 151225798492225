import html2canvas from "html2canvas";
import { showToast } from "./toast";
import { systems } from "./urls";
import jsPDF from "jspdf";
import imageCompression from "browser-image-compression";

export const CONSTANTS = {
  side_nav: [
    { name: "Dashboard", link: "/dashboard" },
    { name: "Appointments", link: "/appointments" },
    { name: "Patients", link: "/patients" },
    { name: "Account", link: "/account" },
    {
      name: "Logout",
      link: "/logout",
    },
  ],
  appointment_filters: ["all", "new", "followup", "routine", "completed"],
  saluatation: ["Mr", "Mrs", "Ms", "Baby"],
  consultation: [
    {
      label: "Chief complaint",
      nav: "",
    },
    {
      label: "Vitals",
      nav: "",
    },
    {
      label: "Report findings",
      nav: "",
    },
    {
      label: "Fees",
      nav: "",
    },
  ],
  genders: ["Male", "Female", "Others"],
  blood_Groups: ["O+", "O-", "A+", "A-", "AB+", "AB-", "B+", "B-", "Unsure"],
  find_us: [
    "Newspaper",
    "TV",
    "Facebook",
    "Instagram",
    "Social Media",
    "Friends",
    "Doctors",
    "Family",
    "Old Patient",
  ],
  selections: ["New", "Followup", "Routine"],
  record: ["Prescription", "Lab", "Scan", "Others"],
  months: {
    "01": "Jan",
    "02": "feb",
    "03": "march",
    "04": "apr",
    "05": "May",
    "06": "jun",
    "07": "July",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  },
};

export const AppointmentTypes = [
  {
    system: "http://terminology.hl7.org/CodeSystem/v2-0276",
    code: "WALKIN",
    version: "2.9",
    display: "A previously unscheduled walk-in visit",
  },
  {
    system: "http://terminology.hl7.org/CodeSystem/v2-0276",
    code: "FOLLOWUP",
    version: "2.9",
    display: "A follow up visit from a previous appointment",
  },
  {
    system: "http://terminology.hl7.org/CodeSystem/v2-0276",
    code: "ROUTINE",
    version: "2.9",
    display: "Routine appointment - default if not valued",
  },
];
export const handleDoctorname = (text = "") => {
  return text?.toLowerCase()?.startsWith("dr") ||
    text?.toLowerCase()?.startsWith("dr.")
    ? text?.toUpperCase()
    : `Dr. ${text?.toUpperCase()}`;
};
export const days = {
  0: "sun",
  1: "mon",
  2: "tue",
  3: "wed",
  4: "thu",
  5: "fri",
  6: "sat",
};

export const getDuration = (time1, time2) => {
  const [hours1, minutes1] = time1.split(":").map(Number);
  const [hours2, minutes2] = time2.split(":").map(Number);
  const totalMinutes1 = hours1 * 60 + minutes1;
  const totalMinutes2 = hours2 * 60 + minutes2;
  const difference = totalMinutes2 - totalMinutes1;
  return difference;
};

const months = CONSTANTS.months;
export const handleAddDates = (selectedDate, daysToAdd) => {
  let startDate = new Date(selectedDate);
  let numberOfDaysToAdd = parseInt(daysToAdd);
  let endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + numberOfDaysToAdd);
  let formattedEndDate = endDate.toISOString().substring(0, 10);
  return formattedEndDate;
};

export const calculateWeeksAndDaysFromDate = (startDateStr) => {
  const startDate = new Date(startDateStr);
  const today = new Date();
  const diffInMilliseconds = today - startDate;
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  const weeks = Math.floor(diffInDays / 7);
  const days = diffInDays % 7;
  return { weeks, days };
};
export const formatdate = (strdate) => {
  const date = `${strdate.split("-")[2]}-${months[strdate.split("-")[1]]}-${
    strdate.split("-")[0]
  }`;
  return date;
};

export const StoreToken = (key, body) => {
  localStorage.setItem(key, JSON.stringify(body));
  return "succesfully saved";
};
export const UpdateToken = (key, newElement) => {
  try {
    const existingDataString = localStorage.getItem(key);
    let existingData = existingDataString ? JSON.parse(existingDataString) : [];
    existingData.unshift(newElement);
    localStorage.setItem(key, JSON.stringify(existingData));
  } catch (error) {
    console.error("Error occurred", error);
  }
};
export const RetriveToken = (key) => {
  const token = localStorage.getItem(key);
  return JSON.parse(token);
};

export const RemoveToken = (key) => {
  localStorage.removeItem(key);
  return null;
};

export const snomedData = (item) => {
  const coding = {
    code: item?.conceptId,
    display: item?.term,
    system: systems.snomed,
    version: item?.version ? item?.version : "",
  };
  return coding;
};

export const AppointmentDatafilterAndSortData = (data) => {
  const pendingItems = data?.filter((item) => item.status === "pending");
  const sortedData = pendingItems;
  sortedData?.sort((a, b) => {
    const timeA = new Date(`2023-01-01T${a.appointment_slot?.split("-")[0]}`);
    const timeB = new Date(`2023-01-01T${b.appointment_slot?.split("-")[0]}`);

    return timeA - timeB;
  });
  const sorteddata = sortedData;
  return sorteddata;
};
export const CompletedAppointmentDatafilterAndSortData = (data) => {
  const completedItems = data?.filter((item) => item.status === "completed");
  const sortedData = completedItems;
  sortedData?.sort((a, b) => {
    const timeA = new Date(`2023-01-01T${a.appointment_slot?.split("-")[0]}`);
    const timeB = new Date(`2023-01-01T${b.appointment_slot?.split("-")[0]}`);

    return timeA - timeB;
  });
  const sorteddata = sortedData;
  return sorteddata;
};

export const capitalizeWord = (word) => {
  if (word.length === 0) return word;
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

export const imgtobase64 = (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const isImage = file.type.startsWith("image/");
      const isPDF = file.type === "application/pdf";

      if (!isImage && !isPDF) {
        showToast("error", "Please select an image or PDF file");
        reject(new Error("Invalid file type"));
        return;
      }

      let processedFile = file;

      if (isImage) {
        const options = {
          maxSizeMB: 0.2,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        processedFile = await imageCompression(file, options);
        if (processedFile.size > 1 * 1024 * 1024) {
          showToast(
            "error",
            "Selected file more than 1MB after compression, please select a smaller one"
          );
          reject(new Error("File size exceeds 1MB after compression"));
          return;
        }
      }

      const reader = new FileReader();
      reader.readAsDataURL(processedFile);
      reader.onload = () =>
        resolve({
          base64: reader.result,
          type: processedFile.type,
          name: processedFile.name,
        });
      reader.onerror = (error) => reject(error);
    } catch (error) {
      reject(error);
    }
  });
};

export const isDesktopOrLaptop = window?.innerWidth >= 1280;
export const istab = window?.innerWidth <= 1279 && window?.innerWidth >= 680;
export const ismobile = window?.innerWidth <= 679 && window?.innerWidth >= 480;
export const isSmallMobile = window?.innerWidth < 480;
export const checkWhetherDesktopOrMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? "Mobile"
    : "Desktop";
};

export const PrintPDF = async (Pid, e, headId, footId) => {
  e.preventDefault();
  const content = document.getElementById(Pid);
  const header = document.getElementById(headId);
  const footer = document.getElementById(footId);
  const headerCanvas = await html2canvas(header, { scale: 1, useCORS: true });
  const headerImgData = headerCanvas.toDataURL("image/png");
  const footerCanvas = await html2canvas(footer, { scale: 1, useCORS: true });
  const footerImgData = footerCanvas.toDataURL("image/png");
  const contentCanvas = await html2canvas(content, {
    scale: 1,
    useCORS: true,
  });
  const contentImgData = contentCanvas.toDataURL("image/png");

  const pdf = new jsPDF({
    orientation: "portrait",
    compress: true,
    format: "a4",
    unit: "px",
  });

  const pageHeight = pdf.internal.pageSize.getHeight();
  const pageWidth = pdf.internal.pageSize.getWidth();

  const headerHeight = headerCanvas.height * (pageWidth / headerCanvas.width);
  const footerHeight = footerCanvas.height * (pageWidth / footerCanvas.width);
  const bodyHeight = pageHeight - headerHeight - footerHeight;

  const imgProps = pdf.getImageProperties(contentImgData);
  const contentMaxWidth = pageWidth * 0.95;
  const contentMaxHeight =
    Pid === "pharmacy-pdf" ? pageHeight : bodyHeight * 0.95;

  const widthScale = contentMaxWidth / imgProps.width;
  const heightScale = contentMaxHeight / imgProps.height;
  const scale = Math.min(widthScale, heightScale);

  const finalContentWidth = imgProps.width * scale;
  const finalContentHeight = imgProps.height * scale;

  const xOffset = (pageWidth - finalContentWidth) / 2;

  let heightLeft = finalContentHeight;

  const addHeaderFooter = (pdf) => {
    pdf.addImage(headerImgData, "PNG", 0, 0, pageWidth, headerHeight);
    pdf.addImage(
      footerImgData,
      "PNG",
      0,
      pageHeight - footerHeight,
      pageWidth,
      footerHeight
    );
  };

  pdf.addImage(
    contentImgData,
    "PNG",
    xOffset,
    50,
    finalContentWidth,
    finalContentHeight
  );
  addHeaderFooter(pdf);
  heightLeft -= bodyHeight;

  while (heightLeft > 0) {
    pdf.addPage();
    pdf.addImage(
      contentImgData,
      "PNG",
      xOffset,
      50,
      finalContentWidth,
      finalContentHeight
    );
    addHeaderFooter(pdf);
    heightLeft -= bodyHeight;
  }

  const base64String = pdf.output("datauristring");
  return base64String;
};

export const formatter = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
});

export const sendSlotDetailsToWtsApp = async (
  phoneId,
  token,
  phoneNumber,
  msg
) => {
  const response = await fetch(
    `https://graph.facebook.com/v17.0/${phoneId}/messages`,
    {
      method: "POST",
      headers: {
        Authorization: ` Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        messaging_product: "whatsapp",
        to: phoneNumber,
        type: "template",
        template: {
          name: "appointment_book",
          language: {
            code: "en",
          },
          components: [
            {
              type: "body",
              parameters: [{ type: "text", text: msg }],
            },
          ],
        },
      }),
    }
  );
  const JSONData = await response.json();
  if (JSONData?.contacts) {
    showToast("success", "Succesfully shared to your WhatsApp");
  }
};
