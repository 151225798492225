import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { UserCard } from "../components/UserCard";
import { URL } from "../utility/urls";
import { useEffect, useState } from "react";
import {
  capitalizeWord,
  CONSTANTS,
  imgtobase64,
  isDesktopOrLaptop,
  isSmallMobile,
  istab
} from "../utility/const";
import { TextInput } from "../components/textinput";
import { showToast } from "../utility/toast";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { AutoCompleteComponent } from "../components/autocomplete";
import { addUserData } from "../redux/features/userData";
import { RadioButton } from "../components/RadioBtn";
const AccountCard = ({ label, data }) => {
  const address = data?.organization?.address[0];
  return (
    <Stack spacing={0.5}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="subtitle1">{label}</Typography>
      </Stack>
      <Stack
        position={"relative"}
        direction={isDesktopOrLaptop || istab ? "row" : "column"}
        alignItems={isDesktopOrLaptop || istab ? "center" : ""}
        spacing={1}
        padding={2}
        borderRadius={2}
        sx={{
          background: "white",
          boxShadow: "0px 0px 8px rgba(75, 165, 250, 0.5)",
        }}
      >
        <div>
          <Typography variant="subtitle1">
            {data?.organization?.name}
          </Typography>
          <Typography variant="body2">
            {data?.organization?.telecom[0]?.value}
          </Typography>

          <Typography variant="body2">{`${capitalizeWord(
            address?.text
          )}, ${capitalizeWord(address?.city)} ${capitalizeWord(
            address?.state
          )} ${address?.postalCode} ${capitalizeWord(
            address?.country
          )}`}</Typography>
        </div>
      </Stack>
    </Stack>
  );
};

export const Account = () => {
  const navigate = useNavigate();
  const {token } = useSelector((state) => state.login);
  const { user_data, organization,} = useSelector(
    (state) => state?.user
  );
  const dispatch = useDispatch();
  const { organization_data } = useSelector((state) => state?.organization);
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [profile_pic, setProfilePic] = useState("");
  const [salut, setSalutation] = useState("");
  useEffect(() => {
    if (user_data) {
      const data = user_data[0];
      setProfilePic(
        data?.profile_pic?.startsWith("data")
          ? data?.profile_pic
          : `data:image/jpeg;base64,${data?.profile_pic}`
      );
      setName(data?.name);
      setGender(data?.gender);
      setPhone(data?.phoneNumber);
      setSalutation(data?.salutation);
    }
  }, []);
  const handleUploadImage = async (img) => {
    try {
      const base64Data = await imgtobase64(img);
      setProfilePic(base64Data?.base64);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const [loading, setLoading] = useState(false);
  const Update_user_details = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(URL.userUpdate(user_data[0]?._id), {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          salutation: salut,
          phoneNumber: phone,
          gender,
          profile_pic: profile_pic,
          practitioner_reference: [],
          organization_reference: organization,
          user_role: user_data[0]?.user_role,
          profile_id: user_data[0]?.profile_id,
        }),
      });
      if (response.ok) {
        const jsonData = await response.json();
        if (jsonData.status === "success") {
          showToast("success", jsonData?.message);
          dispatch(addUserData(jsonData?.data));
          setIsEdit(false);
        } else {
          showToast("error", jsonData?.message);
        }
      } else {
        console.error("API call failed:", response.status);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Stack>
      <Stack display={"flex"} flexDirection={"column"} gap={2}>
        <Box>
          <Typography variant="subtitle1">Personal Information</Typography>
          <UserCard
            data={user_data}
            onClick={(e) => {
              e.preventDefault();
              setIsEdit(!isEdit);
            }}
          />
        </Box>
        {JSON.stringify(organization_data) !== "{}" && (
          <AccountCard
            label={"Organization Information"}
            data={organization_data}
          />
        )}
      </Stack>
      {isEdit && (
        <Modal
          open={isEdit}
          onClose={() => {
            setIsEdit(!isEdit);
          }}
          center
          styles={{
            modal: {
              flex: 1,
              width: isDesktopOrLaptop ? "45%" : isSmallMobile ? "90%" : "80%",
              height: "80%",
              borderRadius: 8,
              padding: 16,
            },
          }}
        >
          <Stack display={"flex"} flexDirection={"column"} gap={2}>
            <Typography variant="h6">Update Profile</Typography>
            <form
              onSubmit={Update_user_details}
              className="flex flex-col gap-8 items-center"
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={1}
                width={"100%"}
              >
                <label htmlFor="profilepic">
                  <div className="h-12 w-12 mb-3 rounded-full flex items-center justify-center cursor-pointer border border-blue-400">
                    <Avatar src={profile_pic} alt="" />

                    <input
                      id="profilepic"
                      className="hidden w-full h-full"
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleUploadImage(e.target.files[0])}
                    />
                  </div>
                </label>
                <Box display={"flex"} gap={1} flex={1}>
                  <AutoCompleteComponent
                    label="Salutations"
                    value={salut}
                    setValue={(newvalue) => setSalutation(newvalue)}
                    options={CONSTANTS.saluatation}
                  />
                  <TextInput
                    className={"w-full"}
                   
                    label={"Name"}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    required={true}
                  />
                </Box>
                <RadioButton
                  label="Gender"
                  data={CONSTANTS.genders}
                  value={gender}
                  setValue={setGender}
                />
                <TextInput
                  className={"w-full"}
                  label={"Phone number"}
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  required={true}
                />
              </Box>
              <Button type="submit" variant="contained">
                SUBMIT
              </Button>
            </form>
          </Stack>
        </Modal>
      )}
    </Stack>
  );
};
