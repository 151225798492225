import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
export const Dropdown = React.memo(
  ({
    options = [],
    id,
    label,
    value,
    setValue,
    required = false,
    pr_name = "",
    doc = false,
    size,
  }) => {
    return (
      <FormControl size={size ? size : "medium"} fullWidth>
        <InputLabel id={"Select"}>{label}</InputLabel>
        <Select
          label={label}
          required={required}
          labelId="select-label"
          value={JSON.stringify(value)}
          onChange={(e) => {
            setValue(JSON.parse(e.target.value));
          }}
          id={id}
        >
          {options &&
            options?.map((item, index) => (
              <MenuItem key={index} value={JSON.stringify(item)}>
                {pr_name
                  ? doc
                    ? item[`${pr_name}`]?.toLowerCase()?.startsWith("dr") ||
                      item[`${pr_name}`]?.toLowerCase()?.startsWith(`dr.`)
                      ? item[`${pr_name}`]
                      : `${"Dr."} ${item[`${pr_name}`]}`
                    : item[`${pr_name}`]
                  : item}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }
);
