import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AppointmentTypes, getDuration, isDesktopOrLaptop } from "../utility/const";
import { RadioButton } from "./RadioBtn";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { systems, URL } from "../utility/urls";
import { addPatientData } from "../redux/features/patientData";
import { addAppointmentData } from "../redux/features/appointmentData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isDesktopOrLaptop ? "40%" : "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: isDesktopOrLaptop ? 4 : 2,
  height: "90%",
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  gap: 1,
  overflowY: "auto",
};
export const WalkInModal = ({ open, onClose, pat_data }) => {
  const nav = useNavigate();
  const { token } = useSelector(
    (state) => state.login
  );
  const { organization, selectedDoctor } = useSelector(
    (state) => state?.user
  );

  const [loading, setLoading] = useState(false);
  const [Reason, setReason] = useState("");
  const [Type, setType] = useState("");
  const [Fee, setFee] = useState("");
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return '';
    }
    try {
      setLoading(true);
      const specialty = selectedDoctor?.specialty;
      const fSlot = dayjs().format('HH:mm');
      const lslot = dayjs().add(10, 'minute').format('HH:mm');
      const slot = `${fSlot}-${lslot}`
      const date = new Date()
      const response = await fetch(URL.createAppointment, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          appointment: {
            resourecType: "Appointment",
            status: "pending",
            specialty: specialty,
            appointmentType: {
              coding: [AppointmentTypes[0]],
              text: AppointmentTypes[0]?.code,
            },
            reasonCode: [
              {
                coding: [
                  {
                    display: 'Screening - health check',
                    code: '275926002',
                    system: systems.snomed,
                  },
                ],
                text: 'Screening - health check',
              },
            ],
            priority: '01',
            supportingInformation: [
              {
                reference: organization?.reference,
                type: "organization",
                display: organization?.display,
              },
            ],
            start: `${date?.toISOString()?.split('T')[0]}T${slot?.split('-')[0]}:00Z`,
            end: `${date?.toISOString()?.split('T')[0]}T${slot?.split('-')[1]}:00Z`,
            minutesDuration: getDuration(
              slot?.split("-")[0],
              slot?.split("-")[1]
            ),
            participant: [
              {
                actor: selectedDoctor,
                required: "required",
                status: "accepted",
              },
              {
                actor: {
                  reference: pat_data?._id,
                  type: "patient",
                  display: pat_data?.patient?.name?.[0]?.text,
                },
                required: "required",
                status: "accepted",
              },
            ],
          },
          type: "appointment",
          mode: 'Offline',
          isPaid: Fee,
          paymentMode: "",
          slot: slot,
        }),
      });
      const json = await response.json();

      if (json?.status === 'success') {
        dispatch(addAppointmentData(json?.data));
        dispatch(
          addPatientData({ ...pat_data })
        );
        nav('/consultation');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} component={"form"} onSubmit={handleSubmit}>
        <Stack display={"flex"} gap={2} padding={2}>
          <RadioButton
            label="Mode"
            data={["Offline", "Online"]}
            value={Type}
            setValue={(val) => {
              setType(val);
              if (val === "Online") {
                nav("/slotbook");
              }
            }}
          />
          <RadioButton
            label="Client has paid fee"
            data={["Yes", "No"]}
            value={Fee}
            setValue={setFee}
          />
          <Stack display={"flex"} gap={1}>
            <Typography variant="subtitle1">
              Reason for waiving off the fee
            </Typography>
            <TextField
              sx={{
                width: "100%",
              }}
              id="outlined-basic"
              variant="outlined"
              value={Reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Stack>
          <Box alignSelf={"Center"} marginTop={2}>
            <Button type="submit" size="medium" variant="contained">
              Submit
            </Button>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};
