import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useSelector, useDispatch } from "react-redux";
import { URL } from "../utility/urls";
import { isDesktopOrLaptop } from "../utility/const";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  IconButton,
  Chip,
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { showToast } from "../utility/toast";
import { addFees } from "../redux/features/consultation/fees";
import { Text } from "./Text";

export const Fees = (props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.login);
  const { doctors } = useSelector((state) => state.user);
  const { fees } = useSelector((state) => state?.fees);
  const { dr_ref, pt_ref, app_id, org_ref } = props.app_data;
  const consultationFees = doctors?.filter(
    (item) => item?.reference === dr_ref?.reference
  )[0]?.fees;
  const [serviceType, setServiceType] = useState("");
  const [serviceAmount, setServiceAmount] = useState("");
  const handledata = () => {
    const feesList = JSON.parse(JSON.stringify(fees?.fees ? fees?.fees : []));
    const feesListCheck = feesList?.filter(
      (item) => item?.service_name === "consultation Fees"
    );
    dispatch(
      addFees({
        fees: [
          ...feesList,
          feesListCheck?.length < 1
            ? { service_name: "consultation Fees", value: consultationFees }
            : null,
          { service_name: serviceType, value: serviceAmount },
        ].filter(Boolean),
        orgainization_id: org_ref,
        practitioner_id: dr_ref,
        encounter: { appointment: { reference: app_id } },
        patient_id: pt_ref,
      })
    );
    setServiceType("");
    setServiceAmount("");
  };
  const Postdata = async (e) => {
    e.preventDefault();
    if (!fees?.fees?.length > 0) {
      return showToast("error", "Please add Atleast one fees");
    }
    const response = await fetch(URL.createFees, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(fees),
    });
    const jsonData = await response.json();
    if (jsonData?.status === "success") {
      showToast("success", "succesfully done");
      props.close();
    } else {
      showToast("error", "please try again");
    }
  };
  const fetchFees = async () => {
    const response = await fetch(URL.getFees(app_id), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    jsonData?.data
      ? dispatch(addFees(jsonData?.data))
      : dispatch(addFees(fees));
  };
  useEffect(() => {
    fetchFees();
  }, []);
  return (
    <Modal
      open={props.open}
      onClose={props.close}
      center
      styles={{
        modal: {
          flex: 1,
          width: isDesktopOrLaptop ? "50%" : "80%",
          height: "80%",
          borderRadius: 16,
        },
      }}
    >
      <Stack>
        <ConsultationFees data={fees} />
        <Box
          display={"flex"}
          position={"relative"}
          flexDirection={"column"}
          gap={1}
          p={1}
          alignItems={"flex-start"}
        >
          <Typography variant="h5" component="h2" fontWeight={700}>
            Fees
          </Typography>
          <TextField
            label={"Consultation Fees"}
            value={consultationFees}
            disabled
            fullWidth
          />
          <Typography variant="subtitle1">Additional Services</Typography>
          <TextField
            label={"Service Name"}
            value={serviceType}
            onChange={(e) => setServiceType(e.target.value)}
            fullWidth
          />
          <TextField
            label={"Service Fees"}
            value={serviceAmount}
            type="number"
            onChange={(e) => setServiceAmount(e.target.value)}
            fullWidth
          />
          <IconButton
            sx={{ alignSelf: "flex-end" }}
            onClick={(e) => {
              e.preventDefault();
              handledata();
            }}
          >
            <AddCircle fontSize="large" />
          </IconButton>
        </Box>
        <Button
          sx={{
            alignSelf: "center",
          }}
          variant="contained"
          onClick={Postdata}
        >
          Save
        </Button>
      </Stack>
    </Modal>
  );
};

const ConsultationFees = ({ data }) => {
  const dispatch = useDispatch();
  const [totalfee, setTotalFee] = useState("");
  const FeesComponent = ({ data, onDelete }) => {
    return (
      <Stack
        display={"flex"}
        flexDirection={"row"}
        gap={1}
        flexWrap={"wrap"}
        alignItems={"center"}
      >
        <Chip
          variant="filled"
          label={`${data?.service_name} : ${data?.value}`}
          onDelete={onDelete}
        />
      </Stack>
    );
  };
  useEffect(() => {
    const TotalFees = data?.fees?.reduce(
      (acc, val) => (acc += parseFloat(val?.value)),
      0
    );
    setTotalFee(TotalFees);
  }, [data?.fees]);
  const handleDeletefees = (ind) => {
    const feesList = JSON.parse(JSON.stringify(data?.fees ? data?.fees : []));
    const feesListCheck = feesList?.filter((item, _) => _ !== ind);
    dispatch(addFees({ ...data, fees: feesListCheck }));
  };
  return (
    <Stack direction={"row"} display={"flex"} gap={1} flexWrap={"wrap"} py={1}>
      {data?.fees?.map((item, index) => (
        <FeesComponent
          data={item}
          key={index}
          onDelete={() => handleDeletefees(index)}
        />
      ))}
      <Text weight={"600"}>Total : {totalfee}</Text>
    </Stack>
  );
};
