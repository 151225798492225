import { Toaster } from "react-hot-toast";
import { Header } from "./components/header";
import { TopNav } from "./components/topNav";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  ismobile,
  isSmallMobile,
  istab,
  RetriveToken,
} from "./utility/const";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Login } from "./screens/Login";
import {
  app,
  isFCMSupported,
  requestForToken,
  requestNotificationPermission,
} from "./firebase";
import { getMessaging, onMessage } from "firebase/messaging";
import { showToast } from "./utility/toast";
import { addFcmToken } from "./redux/features/fcmTokens";
import { addAppointmentRefreshVar } from "./redux/features/appointmentData";
import { URL } from "./utility/urls";
import { addLoginData } from "./redux/features/logindata";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuth } = useSelector((state) => state.login);
  const [notiyReq, SetNotifyReq] = useState(window.Notification.permission);
  const requestPermission = async () => {
    const permission = await requestNotificationPermission();
    SetNotifyReq(permission);
  };
  const tokenfci = async () => {
    try {
      if (notiyReq === "granted") {
        const printToken = await requestForToken();
        dispatch(addFcmToken(printToken));
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    tokenfci();
    requestPermission();
  }, [notiyReq]);
  useEffect(() => {
    requestPermission();
    const refresh_token = RetriveToken("login");
    if (refresh_token && refresh_token?.refresh_token) {
      const getNewAccessToken = async () => {
        const response = await fetch(URL.getNewAccessToken, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(refresh_token),
        });
        const jsondata = await response.json();
        if (jsondata) {
          dispatch(
            addLoginData({
              phoneNumber: jsondata?.user?.phoneNumber,
              token: jsondata?.access_token,
              profile_id: jsondata?.user?.profile_id,
              isAuth: true,
            })
          );
          navigate("/dashboard");
        } else {
          navigate("/login");
        }
      };
      getNewAccessToken();
    } else {
      navigate("/login");
    }
  }, []);
  const ProctedRoutes = (e) => {
    let appsReload = "";
    if (isFCMSupported()) {
      const messaging = getMessaging(app);
      onMessage(messaging, (payload) => {
        dispatch(addAppointmentRefreshVar((appsReload += "aa")));
        showToast("success", payload?.notification?.body);
      });
    }
    const [headOpen, setHeadopen] = useState(false);
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "14vw 84vw",
          gridTemplateRows: "10vh 90vh",
        }}
      >
        {isSmallMobile || ismobile || istab ? (
          headOpen && (
            <div className="absolute z-30 left-0">
              <Header
                onClick={headOpen}
                close={(e) => {
                  e.preventDefault();
                  setHeadopen(false);
                }}
              />
            </div>
          )
        ) : (
          <div
            style={{
              gridColumn: "1 / 2",
              gridRow: "1 / 2",
            }}
          >
            <Header />
          </div>
        )}
        <div
          style={{
            gridColumn: isSmallMobile || ismobile ? "1/3" : "2/3",
            gridRow: "1 / 2",
            zIndex: 20,
          }}
        >
          <TopNav
            onClick={(e) => {
              e.preventDefault();
              setHeadopen(!headOpen);
            }}
          />
        </div>

        <div
          style={{
            gridColumn: istab || ismobile || isSmallMobile ? "1/3" : "2/3",
            gridRow: "2 / 3",
            padding: 16,
            overflowY: "auto",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setHeadopen(false);
          }}
        >
          <Outlet />
        </div>
      </div>
    );
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />

      <div className="flex flex-row">
        {isAuth && <ProctedRoutes />}
        {location?.pathname === "/login" && <Login />}
      </div>
    </>
  );
}

export default App;
