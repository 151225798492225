import { Box, Stack, Typography } from '@mui/material';
import { Text } from '../Text';
import { CUSTOMCOLORS } from '../../styles';
import dayjs from 'dayjs';

export const VitalsPrev = ({ data, ispdf }) => {
  const bp_basic_data = data?.filter((item) => item?._id !== '');
  const vitals_data = data?.filter((item) => item?._id === '');
  const handleData = (data = [], condition = '') => {
    const grouped_data = data
      ?.flatMap(
        (item) =>
          item?.observations?.filter((val) =>
            condition === 'true'
              ? val.vitalobservation?.code?.text?.includes('blood pressure')
              : !val.vitalobservation?.code?.text?.includes('blood pressure')
          ) || []
      )
      ?.reduce((acc, curr) => {
        const effectiveDateTime = curr.vitalobservation.effectiveDateTime;
        if (!acc[effectiveDateTime]) {
          acc[effectiveDateTime] = {
            _id: effectiveDateTime,
            observations: [],
          };
        }
        acc[effectiveDateTime].observations.push(curr);
        return acc;
      }, {});
    return Object.values(grouped_data);
  };

  const bps_data = handleData(bp_basic_data, 'true');
  const basic_data = handleData(bp_basic_data, 'false');
  const normal_data = handleData(vitals_data, '');
  const handleBodyData = (code = '') => {
    const hei = normal_data?.map((item) => item?.observations);
    const body_data = hei[0]?.filter(
      (item) => item?.vitalobservation?.code?.text === code
    );
    return body_data[0];
  };
  const others_body_data = normal_data
    ?.map((item) => item?.observations)[0]
    ?.filter((item) => {
      const check = item?.vitalobservation?.code?.text;
      return (
        check !== 'Body height' &&
        check !== 'Body weight' &&
        check !== 'BMI' &&
        check !== 'Date of last menstrual period' &&
        check !== 'Estimated date of delivery'
      );
    });
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
    >
      <Typography
        variant='subtitle1'
        color={ispdf ? CUSTOMCOLORS.primary : CUSTOMCOLORS.black}
        fontWeight={ispdf ? '700' : '600'}
      >
        Vitals:
      </Typography>
      {normal_data?.length > 0 && (
        <Stack>
          <Typography
            display={'flex'}
            gap={0.5}
          >
            <Text size={12}>Height: </Text>
            <Text
              size={12}
              weight={600}
            >
              {
                handleBodyData('Body height')?.vitalobservation?.valueQuantity
                  ?.value
              }
              cm{' '}
            </Text>
            <Text size={12}>Weight: </Text>
            <Text
              size={12}
              weight={600}
            >
              {' '}
              {
                handleBodyData('Body weight')?.vitalobservation?.valueQuantity
                  ?.value
              }
              kg
            </Text>
            <Text size={12}> BMI: </Text>

            <Text
              size={12}
              weight={600}
            >
              {handleBodyData('BMI')?.vitalobservation?.valueQuantity?.value}{' '}
            </Text>
          </Typography>
        </Stack>
      )}
      <Stack spacing={1}>
        {bps_data?.length > 0 &&
          bps_data?.map((item, ind) => {
            const handleBpData = (code = '') => {
              const data = item?.observations?.map((ele) => ele);
              const filter_data = data?.filter(
                (val) => val?.vitalobservation?.code?.text === code
              );
              return filter_data[0];
            };
            return (
              <Stack
                key={ind}
                // direction={'row'}
                // justifyContent={'space-between'}
                // alignItems={'center'}
                width={'100%'}
              >
                <Typography
                  display={'flex'}
                  flexWrap={'wrap'}
                  gap={0.5}
                >
                  <Text size={12}>BP: </Text>
                  <Text
                    size={12}
                    weight={600}
                  >
                    {' '}
                    {
                      handleBpData('Systolic blood pressure')?.vitalobservation
                        ?.valueQuantity?.value
                    }
                    /
                    {
                      handleBpData('Diastolic blood pressure')?.vitalobservation
                        ?.valueQuantity?.value
                    }{' '}
                  </Text>
                  <Text size={12}> Time:</Text>
                  <Text
                    size={12}
                    weight={600}
                  >
                    {new Date(item?._id).toString()?.split('GMT')[0]}
                  </Text>
                </Typography>
              </Stack>
            );
          })}
      </Stack>
      <Stack spacing={1}>
        {basic_data?.length > 0 &&
          basic_data?.map((item, ind) => {
            const handleBasicData = (code = '') => {
              const data = item?.observations?.map((ele) => ele);
              const filter_data = data?.filter(
                (val) => val?.vitalobservation?.code?.text === code
              );
              return filter_data[0];
            };

            return (
              <Stack
                key={ind}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'100%'}
              >
                <Typography
                  display={'flex'}
                  flexWrap={'wrap'}
                  gap={0.5}
                >
                  <Text size={12}>Pulse: </Text>
                  <Text
                    size={12}
                    weight={600}
                  >
                    {
                      handleBasicData('Pulse rate')?.vitalobservation
                        ?.valueQuantity?.value
                    }{' '}
                  </Text>
                  <Text size={12}>SPO2: </Text>
                  <Text
                    size={12}
                    weight={600}
                  >
                    {
                      handleBasicData(
                        'Measurement of oxygen saturation at periphery'
                      )?.vitalobservation?.valueQuantity?.value
                    }
                    %
                  </Text>
                  <Text size={12}> Temp: </Text>
                  <Text
                    size={12}
                    weight={600}
                  >
                    {
                      handleBasicData('Body temperature')?.vitalobservation
                        ?.valueQuantity?.value
                    }
                    °F{' '}
                  </Text>
                  <Text size={12}> Resp: </Text>
                  <Text
                    size={12}
                    weight={600}
                  >
                    {
                      handleBasicData('Respiratory rate')?.vitalobservation
                        ?.valueQuantity?.value
                    }
                    brpm
                  </Text>
                  <Text size={12}>Time: </Text>
                  <Text
                    size={12}
                    weight={600}
                  >
                    {new Date(item?._id).toString()?.split('GMT')[0]}
                  </Text>
                </Typography>
              </Stack>
            );
          })}
      </Stack>
      {normal_data?.length > 0 &&
        !ispdf &&
        normal_data[0]?.observations[3]?.vitalobservation?.valueQuantity
          ?.value && (
          <Typography
            display={'flex'}
            flexWrap={'wrap'}
            gap={0.5}
          >
            <Text size={12}>LMP: </Text>
            <Text
              size={12}
              weight={600}
            >
              {dayjs(
                handleBodyData(
                  'Date of last menstrual period'
                )?.vitalobservation?.valueQuantity?.value?.toString()
              ).format('DD-MM-YYYY')}{' '}
            </Text>
            <Text size={12}>EDD: </Text>
            <Text
              size={12}
              weight={600}
            >
              {' '}
              {dayjs(
                handleBodyData(
                  'Estimated date of delivery'
                )?.vitalobservation?.valueQuantity?.value?.toString()
              ).format('DD-MM-YYYY')}{' '}
            </Text>
          </Typography>
        )}
      {normal_data?.length > 0 &&
        normal_data[0]?.observations[5]?.vitalobservation?.code?.text && (
          <Typography
            display={'flex'}
            flexWrap={'wrap'}
            gap={0.5}
          >
            <Text size={12}>
              {others_body_data[0]?.vitalobservation?.code?.text}:{' '}
            </Text>
            <Text
              size={12}
              weight={600}
            >
              {others_body_data[0]?.vitalobservation?.valueQuantity?.value}
            </Text>
          </Typography>
        )}
    </Box>
  );
};
