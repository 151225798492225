import { Box, Typography } from "@mui/material";
import React from "react";
import { isDesktopOrLaptop, ismobile, istab } from "../utility/const";

export const AppointmentCount = React.memo(({ style, label, count, color }) => {
  return (
    <Box
      flex={1}
      display={"flex"}
      gap={2}
      p={isDesktopOrLaptop || istab || ismobile ? 2 : 1}
      alignItems={"center"}
      justifyContent={"center"}
      borderRadius={2}
      style={style}
      flexWrap={isDesktopOrLaptop || istab ? "nowrap" : "wrap-reverse"}
    >
      <Typography
        variant={
          isDesktopOrLaptop || istab || ismobile ? "subtitle1" : "subtitle2"
        }
        lineHeight={1}
      >
        {label}
      </Typography>
      <Typography
        variant={isDesktopOrLaptop ? "h5" : "h6"}
        lineHeight={1}
        color={color}
      >
        {count}
      </Typography>
    </Box>
  );
});
