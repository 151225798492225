import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export const Picker = ({ value, setValue }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{
          paddingY: 0,
          width: "100%",
        }}
        value={value}
        onChange={(val) => {
          const newValue = dayjs(val, "DD-MM-YYYY");
          setValue(newValue);
        }}
        format="DD-MM-YYYY"
      />
    </LocalizationProvider>
  );
};
