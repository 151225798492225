import { createSlice } from '@reduxjs/toolkit';

const WtsSlice = createSlice({
  name: 'wtsapp',
  initialState: { wts_token: '', wts_id: '' },
  reducers: {
    addWtsKeys: (state, action) => {
      const { wts_token, wts_id } = action.payload;
      state.wts_token = wts_token;
      state.wts_id = wts_id;
    },
  },
});

export const { addWtsKeys } = WtsSlice.actions;
export const WtsReducer = WtsSlice.reducer;
