import { Box, Button, Stack } from "@mui/material";
import { CUSTOMCOLORS } from "../styles";
import { IoMdClose } from "react-icons/io";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { isSmallMobile } from "../utility/const";

export const FileShowChip = ({ label, onClick, book }) => {
  return (
    <Box>
      {book ? (
        <Button
          startIcon={<QrCodeScannerIcon color="success" />}
          onClick={onClick}
          sx={{ color: "#000", fontSize: isSmallMobile ? 12 : 14 }}
        >
          {label}
        </Button>
      ) : (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          border={0.5}
          padding={1}
          borderColor={"success.main"}
          borderRadius={2}
          bgcolor={"success.light"}
          color={"success.main"}
          fontSize={12}
          flexWrap={"wrap"}
        >
          {label}
          {onClick && (
            <span
              style={{
                fontSize: 20,
                color: CUSTOMCOLORS.DeIcon,
                cursor: "pointer",
              }}
              onClick={onClick}
            >
              <IoMdClose />
            </span>
          )}
        </Stack>
      )}
    </Box>
  );
};
