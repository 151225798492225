import { Box, CircularProgress } from '@mui/material';
import React from 'react';

export const Loading = ({ size }) => {
  return (
    <Box
      display={'flex'}
      width={'100%'}
      height={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <CircularProgress
        variant='indeterminate'
        color='success'
        size={size ? size : 96}
      />
    </Box>
  );
};
