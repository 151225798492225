import { useSelector, useDispatch } from "react-redux";
import { URL } from "../utility/urls";
import { useEffect, useState } from "react";
import { SearchInput } from "../components/SearchBar";
import { PatientCard } from "../components/patientcard";
import { addPatientData } from "../redux/features/patientData";
import { PatientCreate } from "../components/PatientCreate";
import { Box, Button, Stack } from "@mui/material";
import { isDesktopOrLaptop, istab } from "../utility/const";
import { Loading } from "../components/Loading";
export const AddPatients = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.login);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState("");
  const { organization } = useSelector((state) => state?.user);
  const [patData, setPatData] = useState([]);
  console.log(patData);

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const fetchOrgData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        URL.getPatientOrg(organization?.reference, page),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const jsonData = await response.json();
      if (jsonData?.status === "success") {
        setPatData(jsonData?.data?.patients, page);
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const fetchOrgDataBySearch = async (search) => {
    setLoading(true);
    try {
      const response = await fetch(
        URL.getPatientOrgBySearch(organization?.reference, search),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const jsonData = await response.json();
      console.log(jsonData);

      if (jsonData?.status === "success") {
        setPatData(jsonData?.data);
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (search) {
      fetchOrgDataBySearch(search);
    } else {
      fetchOrgData();
    }
  }, [search, page]);
  return (
    <Stack>
      {open && (
        <PatientCreate open={open} onClose={setOpen} patient_data={undefined} />
      )}
      <Stack spacing={2}>
        <Box
          display={"flex"}
          flexDirection={isDesktopOrLaptop || istab ? "row" : "column"}
          gap={2}
          alignItems={isDesktopOrLaptop || istab ? "center" : ""}
          width={"100%"}
        >
          <Stack flex={1}>
            <SearchInput
              placeholder={"Search by name / phone / reference id"}
              value={search}
              setValue={setSearch}
            />
          </Stack>
          <Button
            size={isDesktopOrLaptop || istab ? "large" : "medium"}
            onClick={(e) => {
              e.preventDefault();
              setOpen(!open);
              dispatch(addPatientData(undefined));
            }}
            variant="contained"
            color="success"
            sx={{
              "&:hover": {
                backgroundColor: "#23A330",
              },
              alignSelf: "end",
            }}
          >
            Add Patient
          </Button>

        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          {loading ? (
            <Loading />
          ) : (
            <>
              {patData?.map((item) => (
                <PatientCard key={item} data={item} />
              ))}
            </>
          )}
        </Box>
      </Stack>
      <Stack position={"relative"}>
        {page > 1 && (
          <Button
            sx={{
              alignSelf: "flex-start",
            }}
            variant="outlined"
            onClick={(e) => {
              e.preventDefault();
              return setPage((page) => page - 1);
            }}
          >
            prev
          </Button>
        )}
        {patData?.length >= 10 && (
          <Button
            variant="outlined"
            sx={{ position: "absolute", right: 0 }}
            onClick={(e) => {
              e.preventDefault();
              return setPage((page) => page + 1);
            }}
          >
            next
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
