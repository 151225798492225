import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { VscThreeBars } from "react-icons/vsc";
import {
  ismobile,
  isSmallMobile,
  istab,
  isDesktopOrLaptop,
} from "../utility/const";
import {
  AppBar,
  Toolbar,
  Avatar,
  Box,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import { CiBellOn } from "react-icons/ci";
import { URL } from "../utility/urls";
import { CUSTOMCOLORS } from "../styles";
import { NotifyModal } from "./NotifyModal";
import { useNavigate } from "react-router-dom";

export const TopNav = React.memo(({ onClick }) => {
  const nav = useNavigate();
  const { user_data } = useSelector((state) => state?.user);

  const { refreshVar } = useSelector((state) => state?.appointment);
  const { profile_id } = useSelector((state) => state.login);

  const [notifyData, setNotifyData] = useState([]);
  const [open, setOpen] = useState(false);
  const getNoityData = async () => {
    const data = await fetch(URL.getNotifyData(profile_id), {
      method: "GET",
    });
    const jsonData = await data.json();
    const docs_data = jsonData?.data?.filter(
      (item) => item?.sending_to === "user"
    );
    setNotifyData(docs_data);
  };
  useEffect(() => {
    getNoityData();
  }, [refreshVar, profile_id]);
  const filter_seen = notifyData?.filter((item) => item?.seen === false).length;

  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width:
          istab || ismobile || isSmallMobile ? "100%" : `calc(100% - ${14}vw)`,
        height: istab ? "8vh" : "10vh",
        background: CUSTOMCOLORS.fadeblue,
        justifyContent: "center",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: CUSTOMCOLORS.fadeblue,
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: isDesktopOrLaptop || istab ? 2 : 0,
          }}
        >
          {(istab || ismobile || isSmallMobile) && (
            <IconButton onClick={onClick} edge="start" size="large">
              <VscThreeBars color={CUSTOMCOLORS.black} />
            </IconButton>
          )}
          <Stack
            alignItems={isDesktopOrLaptop || istab ? "center" : ""}
            gap={isDesktopOrLaptop || istab ? 2 : 0}
            display={"flex"}
            flexDirection={isDesktopOrLaptop || istab ? "row" : "column"}
          >
            <Typography
              variant={isDesktopOrLaptop || istab ? "subtitle1" : "subtitle2"}
              lineHeight={1}
            >
              Welcome,
            </Typography>
            <Typography
              variant={isDesktopOrLaptop || istab ? "h6" : "subtitle1"}
            >
              {user_data[0]?.salutation} {user_data[0]?.name}
            </Typography>
          </Stack>
        </Stack>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: 1,
          }}
        >
          <IconButton
            sx={{ position: "relative" }}
            onClick={() => setOpen(true)}
          >
            <CiBellOn color={CUSTOMCOLORS.black} size={32} />
            {filter_seen > 0 && (
              <Box
                sx={{
                  width: "1rem",
                  height: "1rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  top: 4,
                  right: 8,
                  bgcolor: "error.main",
                  borderRadius: "50%",
                }}
              >
                <Typography variant="body2" color="white">
                  {filter_seen > 9 ? "10+" : filter_seen}
                </Typography>
              </Box>
            )}
          </IconButton>
          <NotifyModal open={open} onClose={handleOnClose} data={notifyData} />
          <Avatar
            src={
              user_data[0]?.profile_pic?.startsWith("data")
                ? user_data[0]?.profile_pic
                : `data:image/jpeg;base64,${user_data[0]?.profile_pic}`
            }
            alt=""
            sx={{
              width: isDesktopOrLaptop || istab ? 48 : 42,
              height: isDesktopOrLaptop || istab ? 48 : 42,
              borderRadius: "50%",
              objectFit: "cover",
              border: 1,
              borderColor: CUSTOMCOLORS.border,
            }}
            onClick={() => nav("/account")}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
});
