import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user_data: {},
    doctors: [],
    organization: {},
    selectedDoctor: {}
};

const UserData = createSlice({
    name: 'User',
    initialState,
    reducers: {
        addUserData: (state, action) => {
            state.user_data = action.payload
        },
        addDoctors: (state, action) => {
            state.doctors = action.payload
        },
        addOrganization: (state, action) => {
            state.organization = action.payload
        },
        addSelectedDoctor: (state, action) => {
            state.selectedDoctor = action.payload
        }
    },
});

export const { addUserData, addDoctors, addOrganization, addSelectedDoctor } = UserData.actions;
export const UserReducer = UserData.reducer;