import React, { useState, useRef, useEffect } from "react";
import logo from "../assets/logo2.png";
import AuthCode from "react-auth-code-input";
import { URL } from "../utility/urls";
import { showToast } from "../utility/toast";
import { useDispatch } from "react-redux";
import {
  isDesktopOrLaptop,
  isSmallMobile,
  StoreToken,
} from "../utility/const";
import { useNavigate } from "react-router-dom";
import { addLoginData } from "../redux/features/logindata";
import {
  Avatar,
  Box,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CUSTOMCOLORS } from "../styles";
import { LoadingButton } from "@mui/lab";
export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [timer, setTimer] = useState(180);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const timerRef = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneLoading, setPhoneLoading] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [code, setCode] = useState("");
  const [trace_id, setTrace_Id] = useState("");
  const fetchData = async (e) => {
    e.preventDefault();
    try {
      setPhoneLoading(true);
      const response = await fetch(URL.generateOtp, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber,
          role: ["user"],
        }),
      });
      if (response?.ok) {
        const jsonData = await response.json();
        if (jsonData?.status === "success") {
          setTrace_Id(jsonData?.data?.trace_id);
          showToast(jsonData?.status, "Otp send to your whatsApp");
          setSendOtp(true);
        } else {
          showToast("error", jsonData?.message);
        }
      }
    } catch (error) {
      showToast("error", error?.message);
    } finally {
      setPhoneLoading(false);
    }
  };
  const ValidateOtp = async (e) => {
    e.preventDefault();
    try {
      setOtpLoading(true);
      const response = await fetch(URL.validateOtp, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phoneNumber, trace_id, code }),
      });
      if (response.ok) {
        const jsonData = await response.json();
        if (jsonData.status === "success") {
          dispatch(
            addLoginData({
              token: jsonData?.data?.access_token,
              phoneNumber: jsonData?.data?.user?.phoneNumber,
              profile_id: jsonData?.data?.user?.profile_id,
              isAuth: true,
            })
          );
          StoreToken("login", {
            refresh_token: jsonData?.data?.refresh_token,
          });
          showToast("success", jsonData?.message);
          navigate("/dashboard");
          setCode("");
          setOtpLoading(false);
        } else {
          console.error("API call failed:", response.status);
          showToast("error", jsonData?.message);
          setOtpLoading(false);
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setOtpLoading(false);
    } finally {
      setOtpLoading(false);
    }
  };
  const startTimer = () => {
    setIsTimerRunning(true);
    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(timerRef.current);
          setIsTimerRunning(false);
        }
        return prevTimer - 1;
      });
    }, 1000);
  };
  const resetTimer = () => {
    setTimer(180);
    setIsTimerRunning(false);
    clearInterval(timerRef.current);
  };
  useEffect(() => {
    if (sendOtp) {
      startTimer();
    }
    return () => {
      clearInterval(timerRef.current);
    };
  }, [sendOtp]);

  return (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100dvh",
        backgroundColor: "#EDF6FF",
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        justifyContent={"center"}
        gap={8}
        sx={{
          background: CUSTOMCOLORS.white,
          borderRadius: 8,
          boxShadow: "0px 0px 8px 0px #0000004A",
        }}
      >
        <Stack
          gap={8}
          width={1}
          p={isDesktopOrLaptop ? 16 : isSmallMobile ? 2 : 8}
        >
          <Avatar
            sx={{
              alignSelf: "center",
              width: 313,
              height: 80,
              borderRadius: 1,
            }}
            src={logo}
          />

          <form onSubmit={sendOtp ? ValidateOtp : fetchData}>
            <Stack gap={4} width={1}>
              {!sendOtp ? (
                <>
                  <TextField
                    id="phoneNumber"
                    label="Enter Phone Number"
                    placeholder="+91"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  <LoadingButton
                    sx={{
                      alignSelf: "center",
                    }}
                    type="submit"
                    variant="contained"
                    size="large"
                    loading={phoneLoading}
                  >
                    Get otp
                  </LoadingButton>
                </>
              ) : (
                <Stack gap={4}>
                  <Stack gap={2} alignSelf={"center"}>
                    <AuthCode
                      onChange={(e) => setCode(e)}
                      allowedCharacters="numeric"
                      inputClassName="w-10 border mr-1 p-2 text-center rounded border-blue-300 focus:outline-blue-400"
                    />
                    <Typography
                      onClick={(e) => {
                        if (!isTimerRunning) {
                          setPhoneNumber(phoneNumber);
                          resetTimer();
                          startTimer();
                          fetchData(e);
                        }
                      }}
                      sx={{
                        color: isTimerRunning
                          ? "rgb(100 116 139)"
                          : "rgb(59 130 246)",
                        cursor: "pointer",
                        fontSize: "14px",
                    
                      }}
                    >
                      {isTimerRunning ? `Resend OTP : ${timer}s` : "Resend OTP"}
                    </Typography>
                  </Stack>
                  <LoadingButton
                    sx={{
                      alignSelf: "center",
                    }}
                    type="submit"
                    variant="contained"
                    size="large"
                    loading={otpLoading}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              )}
            </Stack>
          </form>
        </Stack>
      </Box>
    </Stack>
  );
};
