import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  fees: {
    fees: [],
    orgainization_id: {},
    practitioner_id: {},
    encounter: {},
    patient_id: {},
  },
};

const FeesSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    addFees: (state, action) => {
      state.fees = action.payload;
    },
  },
});

export const { addFees } = FeesSlice.actions;
export const FeesReducer = FeesSlice.reducer;
