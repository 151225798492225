import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: '',
  profile_id: '',
  isAuth: false,
  phoneNumber: '',
};

const LoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    addLoginData: (state, action) => {
      const { token, profile_id, phoneNumber, isAuth } = action.payload;
      state.profile_id = profile_id;
      state.token = token;
      state.phoneNumber = phoneNumber;
      state.isAuth = isAuth;
    },
  },
});

export const { addLoginData } = LoginSlice.actions;
export const Loginreducer = LoginSlice.reducer;
