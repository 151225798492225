import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    organization_data: {}
}

const organizationSlice = createSlice({
    name: "organization",
    initialState,
    reducers: {
        addOrganizationData: (state, action) => {

            state.organization_data = action.payload
        }
    }
})

export const { addOrganizationData } = organizationSlice.actions
export const organizationReducer = organizationSlice.reducer