import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fcmtoken: '',
  doctor_fcmTokens: [],
  serverApiFCM: '',
};

const FcmTokensSlice = createSlice({
  name: 'fcmtokens',
  initialState,
  reducers: {
    addFcmToken: (state, action) => {
      state.fcmtoken = action.payload;
    },
    addDoctor_fcmTokens: (state, action) => {
      state.doctor_fcmTokens = action.payload;
    },
    addServerFCMAPI: (state, action) => {
      state.serverApiFCM = action.payload;
    },
  },
});

export const {
  addFcmToken,
  addDoctor_fcmTokens,
  addServerFCMAPI,
} = FcmTokensSlice.actions;
export const FcmTokensReducer = FcmTokensSlice.reducer;